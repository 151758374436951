import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import styles from "./ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { newcategory, categoryById } from "../../../services/Ecommerce/category";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router";

const CategoryUpdate = () => {
  const navigate = useNavigate();
  const [src, setSrc] = useState([]);
  const {id} = useParams();
  const [getCategoryData, setGetCategoryData] = useState({});
 

  const getCategory = async () => {
    let { data: res } = await categoryById(id);
    setGetCategoryData(res.data)
  }

  useEffect(() => {
    getCategory()
  }, [id, getCategoryData]);

  console.log("getCategoryData", getCategoryData?.categoryname);
  const initialValues = { categoryname: getCategoryData?.categoryname, description: getCategoryData?.description };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const [files, setFiles] = useState({});
  console.log(files.name, "hkoo");

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("categoryname", formValues.categoryname);
    formdata.append("description", formValues.description);
    if (
      formValues.categoryname && formValues.description 
    ) {
      let { data: res } = await newcategory(formdata);
      console.log(res.success);
      if (res.success) {
        Swal.fire(res.message);
        setTimeout(() => {
          navigate("/ecommerce/category");
        }, 1500);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.categoryname) ||
      /^[ ]+$/.test(values.categoryname)
    ) {
      errors.categoryname = "Category is not valid";
    }

    if (!values.categoryname) {
      errors.categoryname = "Category is Required";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.categoryname}
            onChange={handleChange}
            className={styles.inputs}
            name="categoryname"
            label="Category Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.categoryname}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.description}
            onChange={handleChange}
            name="description"
            className={styles.inputs}
            label="Description *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>

        </Grid>
        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.inputs}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.inputs}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <div style={{ background: "white", padding: 20 }}>
                  {src[0] &&
                    src.map((item) => {
                      return (
                        <img
                          src={item}
                          height="110"
                          width={"100"}
                          style={{ marginRight: 20, marginBottom: 20 }}
                        />
                      );
                    })}
                </div>
              </Files>
            </div>
          )}
        </Grid>
        <div
          style={{
            // borderTop: "1px  solid rgba(0, 0, 0, 0.4)",
            width: "100%",
            // borderBottom: "1px  solid rgba(0, 0, 0, 0.4)",
            marginTop: 20,
          }}
        >
        </div>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryUpdate;


