export const categoryList = [
  "Mens",
  "Womens",
  "Kids",
  "Toys",
  "Shirts",
  "T-Shirts",
  "Coat",
  "Jacket",
  "Denims",
  "Polo Shirts",
  "Jeans",
  "Pants",
  "Trousers",
  "Tops",
  "Skirts",
  "Plazo",
  "Shoes",
  "Footwear",
  "Loafers",
  "Sneakers",
];

export const newxcat = [
  { label: "option 1", value: 1 },
  { label: "option 2", value: 2 },
];

export const myxcat = [
  { categoryname: "option 1", _id: 1 },
  { categoryname: "option 2", _id: 2 },
];

export const sizes = [
  { label: "XS", value: "XS" },
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "LM", value: "LM" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
];
