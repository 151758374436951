import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/playlist`;

export const getAllPlaylist = async () => {
  return await axios.get(BASE_LINK + "/");
};

export const getSinglePlaylist = async (id) => {
  return await axios.get(BASE_LINK + `/getById/${id}/`);
};

export const addNewPlaylist = async (formData) => {
  return await axios.post(BASE_LINK + "/", formData);
};
export const updateSinglePlaylist = async (id, formData) => {
  return await axios.patch(BASE_LINK + `/updateById/${id}/`, formData);
};

export const deleteSinglePlaylist = async (id) => {
  return await axios.delete(BASE_LINK + `/deleteById/${id}/`);
};
