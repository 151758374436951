import React from "react";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";

const OrderData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderdet = location.state;

  console.log(orderdet, "prodData");

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            style={{ paddingLeft: "4em", paddingTop: "4em" }}
            className="flex-wrap"
          >
             <h2 className="tabcontent-title">Order Id : {orderdet.order_Id}</h2>
          <p className="tabcontent-subtitle">
            {" "}
            Was placed on{" "}
            <span>
              <strong>{moment(orderdet.createdAt).format("llll")}</strong>
            </span>
          </p>
            <div className="col-12 user-all-details">
              <p>
                Parent Name: {orderdet?.userId?.firstName}{" "}{orderdet?.userId?.lastName}
              </p>
              <p>
                Kid Name: {orderdet?.kidId?.firstName}{" "}
              </p>
              <p>Order Phone: {" "}{orderdet?.shipping_Address?.phone}</p>
              <p>Order Email: {orderdet?.shipping_Address?.email}</p>
              <p>
              Address:{" "}
              {orderdet?.shipping_Address?.address}, &nbsp;
                    {orderdet?.shipping_Address?.locality}, &nbsp;
                    {orderdet?.shipping_Address?.city}, &nbsp;
                    {orderdet?.shipping_Address?.state}
              </p>
              <p>Pin Code: {orderdet?.shipping_Address?.pinCode}</p>
              <p>Total Amount: ₹ {orderdet.orderAmount}/-</p>
              <p>
                Order Created Date: {moment(orderdet.createdAt).format("ll")}
              </p>

              <p>
                Order Status: {orderdet.order_Status}
              </p>

              <p>
                payment Status: {orderdet.paymentStatus}
              </p>

                       
<h4>
  Message : <span style={{
    color: 'red'
  }}>{orderdet?.qcOrderObj?.message} Please Take action According to this message.</span>
</h4>
            
            </div>
          </div>
        </div>
      </div>

      <div id="ContentDiv">
        <div
          className="single-account-wrapper"
          style={{
            padding: "2rem",
          }}
        >
          {/* <h2 className="tabcontent-title">Order Id : {orderdet.order_Id}</h2>
          <p className="tabcontent-subtitle">
            {" "}
            Was placed on{" "}
            <span>
              <strong>{moment(orderdet.createdAt).format("llll")}</strong>
            </span>
          </p> */}
          <div className="myaccount-info-wrapper">
            <div className="order_modal">
              {orderdet?.products_List?.map((prod, index) => {
                return (
                  <>
                    <div className="order_modal_details flex-column flex-md-row">
                      <div className="product_details-image">
                        <div className="order_modal_details__product-image mx-auto">
                          <img
                            src={prod?.product_id?.imagePath}
                            alt=""
                            width="100"
                            height="100"
                          />
                        </div>
                      </div>
                      <div className="product_details-content">
                        <h4 className="order_modal_details__product-name">
                          {prod?.product_id?.product_name}
                        </h4>
                        <p className="order_modal_details__product-quantity">
                          Qty: {prod.qty}
                        </p>
                        <p className="order_modal_details__product-quantity">
                          Amount: ₹ {prod.product_Amount}/-
                        </p>
                        <p>
                          Quicksilver Product :  {prod?.product_id?.quickSliverProduct === true ? 'Yes' : 'No'}
                        </p>
                        <p> Quicksilver product ID : {prod?.product_id?.quickSliverProduct_id}</p>

                      </div>
                    </div>
                  </>
                );
              })}

              <div className="order_modal_total">
                <div className="order_modal_total__subtotal">
                  <p>Phone No.</p>
                  <p className="order_modal_total__subtotal-price">
                    {orderdet?.shipping_Address?.phone}
                  </p>
                </div>
                <div className="order_modal_total__subtotal">
                  <p>User Name</p>
                  <p className="order_modal_total__subtotal-price">
                    {orderdet?.userId?.firstName}&nbsp;
                    {orderdet?.userId?.lastName}
                  </p>
                </div>
                <div className="order_modal_total__subtotal">
                  <p>Address</p>
                  <p className="order_modal_total__subtotal-price order-det-address">
                    {orderdet?.shipping_Address?.address}, &nbsp;
                    {orderdet?.shipping_Address?.locality}, &nbsp;
                    {orderdet?.shipping_Address?.city}, &nbsp;
                    {orderdet?.shipping_Address?.state}
                  </p>
                </div>

                <div className="order_modal_total__subtotal">
                  <p>Pin Code</p>
                  <p className="order_modal_total__subtotal-price">
                    {orderdet?.shipping_Address?.pinCode}
                  </p>
                </div>

                <div className="order_modal_total__subtotal payvalue">
                  <p className="total">Total Amount</p>
                  <p className="order_modal_total__subtotal-price total">
                    ₹ {orderdet.orderAmount}/-
                  </p>
                </div>
                <div className="order_modal_total__subtotal payvalue">
                  <p className="total">Status</p>
                  <p className="order_modal_total__subtotal-price total order-det-status">
                    {orderdet?.order_Status === "cancelledBySeller"
                      ? "Cancelled By Seller"
                      : orderdet?.order_Status === "orderPlaced"
                      ? "Order Placed"
                      : orderdet?.order_Status === "confirmed"
                      ? "Confirmed"
                      : orderdet?.order_Status === "readyToShip"
                      ? "Ready To Ship"
                      : orderdet?.order_Status === "shipped"
                      ? "Shipped"
                      : orderdet?.order_Status === "outForDelivery"
                      ? "Out For Delivery"
                      : orderdet?.order_Status === "delivered"
                      ? "Delivered"
                      : orderdet?.order_Status === "return"
                      ? "Return"
                      : orderdet?.order_Status === "returned"
                      ? "Returned"
                      : "Pending"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderData;
