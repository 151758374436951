import API from "../axios/api";
import axios from "axios";

const BASE_LINK = API.BASE_LINK;
export const getAllAttributeById = async (id) => {
    return await axios.get(BASE_LINK + `/attributes/getById/${id}`);
}
export const deleteSingleAttribute =async (id) => {
    return await axios.delete(BASE_LINK + `/attributes/deleteById/${id}`);

}
export const getAttributesByActivityId = async (id) => {

    return await axios.get(`${BASE_LINK}/attributes/getByActivityId/${id}`)

}

export const addAttributeToDb = async (formData) => {

    return await axios.post(BASE_LINK + "/attributes/", formData);

}
export const updateAttribute = async (id, formData) => {

    return await axios.patch(BASE_LINK + `/attributes/updateById/${id}`, formData);

}

