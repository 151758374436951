import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/subscription`;

export const addNewSubscription = async (formData) => {
  return await axios.post(`${BASE_LINK}/`, formData);
};

export const getSingleSubscription = async (id) => {
  return await axios.get(`${BASE_LINK}/getById/${id}`);
};
export const getSubscriptions = async (id) => {
  return await axios.get(`${BASE_LINK}/`);
};

export const deleteSingleSubscription = async (id) => {
  return await axios.delete(`${BASE_LINK}/deleteById/${id}`);
};

export const updateSingleSubscription = async (id, formData) => {
  return await axios.patch(`${BASE_LINK}/updateById/${id}`, formData);
};

export const getAllActivities = async () => {
  return await axios.get(`${BASE_LINK}/activity/`);
};
