import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getallPrefixes,
  updatePrefix,
  prefixTableDelete,
  getUniverseData,
} from "../../../services/GstDetails/gstDetails";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/GGP-newlogo-wo-word1.png";
import { TextField, MenuItem, Grid } from "@mui/material";

const PrefixTable = () => {
  const [prefixList, setPrefixList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState({});

  const getPrefix = async () => {
    let { data: res } = await getallPrefixes();
    setPrefixList(res.data);
  };

  useEffect(() => {
    getPrefix();
  }, [checked, showAddModal]);

  const delModelHandle = async (id) => {
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const deletePrefix = async (id) => {
    try {
      let { status: status } = await prefixTableDelete(id);
      if (status === 200) {
        console.log("deleted Category Done", id);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const initialValues = {
    name: "",
    type: "",
    description: "",
    method: "",
    values: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectName, SetSelectName] = useState([]);
  const [selectData, SetSelectData] = useState([]);
  const [selectMethod, SetSelectMethod] = useState([]);
  const [showMethod, setShowMethod] = useState(false);

  const getPrefixNameData = async () => {
    let { data: res } = await getUniverseData(
      "entityCode=taxPrefix-name&&status=true"
    );
    SetSelectName(res.data);
  };

  const getPrefixSelectData = async () => {
    let { data: res } = await getUniverseData(
      "entityCode=taxPrefix-type&&status=true"
    );
    SetSelectData(res.data);
  };

  const getPrefixMethodData = async () => {
    let { data: res } = await getUniverseData("entityCode=taxPrefix-method&&status=true");
    SetSelectMethod(res.data);
  };

  useEffect(() => {
    getPrefixSelectData();
    getPrefixNameData();
    getPrefixMethodData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      setFormValues({ ...formValues, [name]: value._id });
      if(e.target.value.valueCode == "transactionType"){
        setShowMethod(true);
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    console.log(formValues, "Form Values");
  };

  const handleEdit = (item) => {
    console.log("item");
    let editItem = item;
    setFormValues(editItem);
    console.log("after selection edit formvalues", formValues);
    setShowAddModal(true);
  };

  const editSubmitHandler = async () => {
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("name", formValues.product_name);
    formdata.append("method", formValues.method);
    formdata.append("type", formValues.type);
    formdata.append("values", formValues.values);

    if (
      formValues.name &&
      formValues.type &&
      formValues.values
    ) {
      // console.log("Product update data", formValues.category._id);
      let { data: res } = await updatePrefix(selectedPrefix?._id, formValues);
      console.log("res", res);
      if (res.success == true) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (!values.method) {
      errors.method = "method is Required";
    }
    if (!values.values) {
      errors.values = "values is Required";
    }
    return errors;
  };

  return (
    <>
      <Link to="/add-taxprefix">
        <button className="button2">Add Prefix</button>
      </Link>
      <div className="myaccount-table table-responsive text-center">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Method</th>
              <th>Value</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {prefixList?.map((prefix, index) => {
              return (
                <tr>
                  <td>{prefix.name}</td>
                  <td>{prefix.type.valueDesc}</td>
                  <td>{prefix.method}</td>
                  <td>{prefix.values}</td>
                  <td>{prefix.description}</td>
                  <td>
                    <MdDelete
                      onClick={() => delModelHandle(prefix._id)}
                      className="product-delete"
                    />
                    <BiEdit
                      onClick={() => {
                        handleEdit(prefix);
                        setSelectedPrefix(prefix);
                      }}
                      className="product-update"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deletePrefix(x)}
      />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Name:</label>
                <select
                  className="catInput"
                  placeholder="Name*"
                  required
                  value={formValues.name}
                  name="name"
                  onChange={handleChange}
                >
                  {selectName?.map((option) => (
                    <option key={option._id} value={option.valueCode}>
                      {option.valueDesc}
                    </option>
                  ))}
                </select>
                <small className="text-red">{formErrors.name}</small>
              </div>
              <div className="catName m-0">
                <label>Type :</label>
               
                          <TextField
            id="outlined-select"
            select
            label="Prefix Type *"
            // value={formValues.type}
            name="type"
            onChange={handleChange}
            helperText="Please select a Type"
          >
            {selectData?.map((option) => (
              <MenuItem key={option._id} value={option} selected={formValues.type === option._id ? true : false}>
                {option.valueDesc}
              </MenuItem>
            ))}
          </TextField>
                <small className="text-red">{formErrors.type}</small>
              </div>

              <div className="catName m-0">
                <label>Description:</label>
                <textarea
                  rows="5"
                  className="catInput"
                  placeholder="Description*"
                  required
                  value={formValues.description}
                  onChange={handleChange}
                  name="description"
                ></textarea>
                <small className="text-red">{formErrors.description}</small>
              </div>
              <div className="catName m-0">
                {/* <label>Method</label>
                <input
                  className="catInput"
                  placeholder="Method*"
                  required
                  value={formValues.method}
                  onChange={handleChange}
                  name="method"
                ></input> */}
                 {showMethod ? (
          <Grid item xs={6}>
          <TextField
          select
            id="outlined-basic"
            value={formValues.method}
            onChange={handleChange}
            label="Method*"
            variant="outlined"
            name="method"
            helperText="Please select Transaction type"
          >
              {selectMethod?.map((option) => (
              <MenuItem key={option._id} value={option.valueCode}>
                {option.valueDesc}
              </MenuItem>
            ))}
            </TextField>
          <p className="text-red">{formErrors.method}</p>
        </Grid>
) : ""}
                <small className="text-red">{formErrors.method}</small>
              </div>
              <div className="catName m-0">
                <label>Values</label>
                <input
                  className="catInput"
                  placeholder="Values *"
                  required
                  value={formValues.values}
                  onChange={handleChange}
                  name="values"
                ></input>
                <small className="text-red">{formErrors.values}</small>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PrefixTable;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Prefix ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
