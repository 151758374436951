import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import NavbarComponent from '../../../Component/Navbar'
import SidenavTabs from '../../../Component/SidenavTabs'

function AddPlaylist() {
    const [toCount, setToCount] = useState(50)
    const [fromCount, setFromCount] = useState(50)
    let navigate = useNavigate()

    const handleNavigation = () => {
        navigate('/home')
    }

    return (
        <div>
            <NavbarComponent />
            <div className='row'>
                {/* <div className="col-3"> */}
                <SidenavTabs showHome={handleNavigation} activeTab={'third'} />
                {/* </div> */}
                <div style={{ backgroundColor: "#F2F4F6" }} className="col">
                    <div className="addPlaylist-outer mx-auto w-50 mt-5">
                        <div className="modelBody">
                            <div className="playlistModelTitle">
                                <h3>Playlist Name</h3>
                                <div className="d-flex playlistModel">
                                    <label htmlFor="">Video Heading: </label>
                                    <input
                                        className="catInput"
                                    ></input>
                                </div>
                            </div>
                            <div className="d-flex playlistModel">
                                <label htmlFor="">Video URL: </label>
                                <input
                                    className="catInput"
                                ></input>
                            </div>
                            <div className="ageGroup flex-column ">
                                <label>Age Group: </label>
                                <div className="ageGroup-inner pt-4">
                                    <p>From</p>
                                    <div className="agegroupBtn-outer">
                                        <button
                                            className="agegroupBtn"
                                            onClick={() => setFromCount(prevState => parseInt(prevState) + 1)}
                                        >
                                            +
                                        </button>
                                        <p>{fromCount}</p>
                                        <button
                                            className="agegroupBtn"
                                            onClick={() => setFromCount(prevState => parseInt(prevState) - 1)}
                                        >
                                            -
                                        </button>
                                    </div>
                                    <p>To</p>
                                    <div className="agegroupBtn-outer">
                                        <button
                                            className="agegroupBtn my-auto"
                                            onClick={() => setToCount(prevState => parseInt(prevState) + 1)}
                                        >
                                            +
                                        </button>
                                        <p>{toCount}</p>
                                        <button
                                            className="agegroupBtn"
                                            onClick={() => setToCount(prevState => parseInt(prevState) - 1)}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modelFooter">
                                <button
                                    className="mainButton modelBtn"
                                    variant="secondary"

                                >
                                    Cancel
                                </button>
                                <Link to="/home">

                                    <button
                                        className="mainButton modelBtn"

                                        variant="primary"

                                    >
                                        Save Video
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* <Modal.Footer></Modal.Footer> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPlaylist
