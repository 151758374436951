import React, { useState, useContext } from "react";
import NavbarComponent from "../../Component/Navbar";
import styles from "../../Assets/Styling/login.module.css";
import pigImg from "../../Assets/images/loginImg.png";
import flag from "../../Assets/images/indiaFlag.png";
import Footer from "../../Component/Footer";
import logo from '../../Assets/images/main-logo.svg'

import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { authContext } from "../../App";
import { LoginAdmin } from "../../services/Admin";

function Login() {
  const [isAuthorized, setIsAuthorized] = useContext(authContext);
  let navigate = useNavigate();
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [showNumInput, setShowNumInput] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const loginWithOtp = () => {
    setShowNumInput(true);
    setShowLoginModel(false);
  };
  // const loginWithFb = () => {
  //   setIsAuthorized(true);
  // };

  const handleLoginAdmin = async () => {
    try {
      if (email == "") {
        alert("Please enter an email")
      }
      else if (password == "") {
        alert("Please enter password")
      }
      else {

        let obj = {
          email,
          password,
        }
        let { data: res } = await LoginAdmin(obj)
        if (res.token) {
          localStorage.setItem("auth_token", res.token)
          setIsAuthorized(true)
          navigate("/categories");

        }
        console.log(res)
      }
    }
    catch (err) {
      if (err?.response?.data?.message) {
        console.log(err?.response?.data?.message)
        alert(err?.response?.data?.message)
      }
      else {
        console.log(err)
        alert(err)
      }
    }
  }


  return (
    <div style={{ overflow: "hidden" }}>
      {/* <NavbarComponent search={false} white={true} /> */}
      <div className={styles.content}>
        <div className="row">
          <div
            className={
              styles.bodyContent +
              " d-flex flex-column d-grid gap-3 justify-content-center"
            }
          >
            {/* <div className="d-flex flex-column"> */}
            <h1>
              Welcome to <br /> Good Good Piggy
            </h1>
            <img className="mx-auto" width="15%" src={logo} alt="" />
            <Modal
              show={showLoginModel}
              onHide={() => setShowLoginModel(false)}
            >
              <div className="modelMain">
                <button className={"mx-auto w-100 " + styles.loginButton}>
                  LOGIN
                </button>

                {/* <Modal.Body style={{ height: "auto" }} className="modelBody ">
                  <div className="d-flex justify-content-center">
                    <div className="row flex-column">
                      <div className="d-grid gap-3">
                        <button
                          className={" " + styles.loginButton2}
                          onClick={loginWithFb}
                        >
                          class="fas fa-mobile-alt"></i><i  LOGIN WITH FACEBOOK
                        </button>
                        <button
                          className={"bg-primary " + styles.loginButton2}
                          onClick={loginWithOtp}
                        >
                          <i class="fas fa-mobile-alt"></i> LOGIN WITH OTP
                        </button>
                        <div className="d-flex">
                          <input
                            className="my-auto me-2"
                            type="checkbox"
                            name=""
                            id=""
                          />{" "}
                          <p className="my-auto">
                            {" "}
                            I agree to the <a href="">
                              Term & conditions
                            </a> and <a href="">Privacy Policy</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    
              </div>
            </Modal.Body> */}
                {/* <Modal.Footer></Modal.Footer> */}
              </div>
            </Modal>
            {showNumInput && (
              <Modal show={showNumInput} onHide={() => setShowNumInput(false)}>
                <button className={"mx-auto w-100 " + styles.loginHeader} style={{ backgroundColor: "white", borderBottom: "solid 1px grey" }}>
                  {/* <img src={logo} alt="" /> */}
                  <p className={styles.otpText}>
                          verify yourself
                        </p>
                </button>
                <Modal.Body>
                  <div className="d-flex justify-content-center p-1">
                    <div style={{ width: "90%" }} className="row flex-column">
                      <div
                        className={
                          "d-grid gap-3 text-center " + styles.loginModel2
                        }
                      >
                       
                        <input
                          autoFocus={autoFocus}
                          className={styles.enterOtpInput}
                          type="text"
                          placeholder="ENTER EMAIL"
                          style={{ outline: "none" }}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          id="otp"
                        />
                        {/* </div> */}
                        {/* <p>
                          Please enter the your password to login
                        </p> */}
                        <input
                          className={styles.enterOtpInput}
                          type="text"
                          style={{ outline: "none" }}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder="ENTER PASSWORD"
                        />
                        <Button className={styles.loginBtn} onClick={() => handleLoginAdmin()}>LOGIN</Button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            <button
              onClick={() => loginWithOtp()}
              className={(styles.primaryBtn, styles.loginHomeBtn + " mx-auto")}
            >
              Begin Journey
            </button>
            {/* </div> */}
          </div>
        </div>
      </div >
      {/* <Footer /> */}
    </div >
  );
}

export default Login;
