import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import { useNavigate } from "react-router";
import { addTaxType, getUniverseData } from "../../../services/GstDetails/gstDetails";

const TaxTypeForm = () => {
  const navigate = useNavigate();
  const initialValues = {
    taxName: "",
    taxValue: "",
    applicableOn: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectName, SetSelectName] = useState([]);

  const getTaxTypeData = async () => {
    let { data: res } = await getUniverseData("entityCode=taxType-taxName&&status=true");
    SetSelectName(res.data);
  };

  useEffect(() => {
    getTaxTypeData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if(name === "taxName"){
      setFormValues({ ...formValues, [name]: value._id });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    console.log(formValues, "Form Values");
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("taxName", formValues.taxName);
    formdata.append("taxValue", formValues.taxValue);
    formdata.append("applicableOn", formValues.applicableOn);

    if (formValues.taxName && formValues.taxValue) {
      //   console.log(formValues, "formValues")
      let { data: res } = await addTaxType(formValues);
      console.log("data", res);
      if (res.success == true) {
        setTimeout(() => {
          alert("Tax Type Added Successfully");
          navigate(`/taxtype-table`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.taxName) {
      errors.taxName = "Tax Name is Required";
    }
    if (!values.taxValue) {
      errors.taxValue = "Tax Value is Required";
    }
    if (!values.applicableOn) {
      errors.applicableOn = "ApplicableOn Value is Required";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            // value={formValues.taxName}
            onChange={handleChange}
            className={styles.inputs}
            label="Tax Name*"
            variant="outlined"
            name="taxName"
            select
            helperText="Please select a Tax Code"
          >
             {/* {selectName?.map((option) => (
              <MenuItem key={option._id} value={option.valueCode}>
                {option.valueDesc}
              </MenuItem>
            ))} */}
            {selectName?.map((option) => (
              <MenuItem key={option._id} value={option} selected={formValues.type === option._id ? true : false}>
                {option.valueDesc}
              </MenuItem>
            ))}
            </TextField>
          <p className="text-red">{formErrors.taxName}</p>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.taxValue}
            onChange={handleChange}
            className={styles.inputs}
            label="Tax Value*"
            variant="outlined"
            name="taxValue"
          />
          <p className="text-red">{formErrors.taxValue}</p>
        </Grid>

        <Grid item xs={6}>
          <label>Applicable From*</label>
          <input
            type="date"
            value={formValues.applicableOn}
            onChange={handleChange}
            className={styles.inputs}
            label="Applicable on*"
            variant="outlined"
            name="applicableOn"
          />
          <p className="text-red">{formErrors.applicableOn}</p>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaxTypeForm;