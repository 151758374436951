import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getUniverseTable,
  updateUniverseTable,
  universeTableDelete,
  updateUniverseStatus
} from "../../../services/GstDetails/gstDetails";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/GGP-newlogo-wo-word1.png";

const UniverseTable = () => {
  const [universeList, setUniverseList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState({});

  const getUniverse = async () => {
    let { data: res } = await getUniverseTable();
    setUniverseList(res.data);
    console.log("universeList", universeList);
  };

  //   console.log("sdfshdfjda", prefixList);

  useEffect(() => {
    getUniverse();
  }, [checked, showAddModal]);

  const delModelHandle = async (id) => {
    console.log("Delete", id);
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const deletePrefix = async (id) => {
    try {
      let { status: status } = await universeTableDelete(id);
      if (status === 200) {
        console.log("deleted Category Done", id);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const initialValues = {
    entityCode: "",
    valueCode: "",
    valueDesc: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const handleEdit = (item) => {
    console.log("item");
    let editItem = item;
    // editItem.category = item.category._id;
    setFormValues(editItem);
    console.log("after selection edit formvalues", formValues);
    setShowAddModal(true);
  };

  const editSubmitHandler = async () => {
    console.log("id");
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("valueCode", formValues.entityCode);
    formdata.append("valueCode", formValues.valueCode);
    formdata.append("valueDesc", formValues.valueDesc);

    if (
      formValues.entityCode &&
      formValues.valueCode &&
      formValues.valueDesc
    ) {
      // console.log("Product update data", formValues.category._id);
      let { data: res } = await updateUniverseTable(
        selectedPrefix?._id,
        formValues
      );
      console.log("res", res);
      if (res.success == true) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.entityCode) {
      errors.entityCode = "Entity Code is Required";
    }

    if (!values.valueCode) {
      errors.valueCode = "Value Code is Required";
    }

    if (!values.valueDesc) {
      errors.valueDesc = "Value Desc is Required";
    }
    // if (!values.sortOrder) {
    //   errors.sortOrder = "sortOrder is Required";
    // }
    return errors;
  };

 
  const updatestatus = async (id) => {
    let { data: res } = await updateUniverseStatus(id);
    console.log("response status", res.data);
  };

  return (
    <>
      <Link to="/add-universetable">
        <button className="button2">Add More</button>
      </Link>
      <div className="myaccount-table table-responsive text-center">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Entity Code</th>
              <th>Value Code</th>
              <th>Value Desc</th>
              <th>Lang.</th>
              <th>Status</th>
              {/* <th>Order</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {universeList?.map((prefix, index) => {
              return (
                <tr>
                  <td>{prefix.entityCode}</td>
                  <td>{prefix.valueCode}</td>
                  <td>{prefix.valueDesc}</td>
                  <td>{prefix.localLang}</td>
                  <td>
                  <select
            defaultValue={prefix?.status}
            name="status"
            onChange={(e) => updatestatus(prefix._id)}
          >
            <option value="true">true</option>
            <option value="false">false</option>
            </select>
                  </td>
                  {/* <td>{prefix.sortOrder}</td> */}
                  <td>
                    <MdDelete
                      onClick={() => delModelHandle(prefix._id)}
                      className="product-delete"
                    />
                    <BiEdit
                      onClick={() => {
                        handleEdit(prefix);
                        setSelectedPrefix(prefix);
                      }}
                      className="product-update"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deletePrefix(x)}
      />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Entity Code:</label>
                <input
                  className="catInput"
                  placeholder="Entity Code*"
                  required
                  value={formValues.entityCode}
                  name="entityCode"
                  onChange={handleChange}
                ></input>
                <small className="text-red">{formErrors.entityCode}</small>
              </div>

              <div className="catName m-0">
                <label>Value Code:</label>
                <input
                  className="catInput"
                  placeholder="Value Code*"
                  required
                  value={formValues.valueCode}
                  onChange={handleChange}
                  name="valueCode"
                ></input>
                <small className="text-red">{formErrors.valueCode}</small>
              </div>
              <div className="catName m-0">
                <label>Value Desc *</label>
                <input
                  className="catInput"
                  placeholder="Value Desc *"
                  required
                  value={formValues.valueDesc}
                  onChange={handleChange}
                  name="valueDesc"
                ></input>
                <small className="text-red">{formErrors.valueDesc}</small>
              </div>
           
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default UniverseTable;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Universe Table ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
