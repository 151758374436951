import React, { useEffect, useState } from "react";
import NavbarComponent from "../../../../Component/Navbar";
import SidenavTabs from "../../../../Component/SidenavTabs";
import uploadIcon from "../../../../Assets/images/uploadicon.png";
import nutrition from "../../../../Assets/images/Nutrition.png";
import "./addAttribute.css";
import background from "../../../../Assets/images/Background.png";
import { TableLiElement } from "../../Home";
import editImg from "../../../../Assets/images/edit.png";
import EllipseImg from "../../../../Assets/images/Ellipse.png";
import GroupImg from "../../../../Assets/images/Group.png";
import pigImg from "../../../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSingleActivity } from "../../../../services/activity";
import { getSingleCategory } from "../../../../services/category";
import { addAttributeToDb } from "../../../../services/attributes";
import Swal from "sweetalert2";

function AddAttribute() {
  const [fromCount, setFromCount] = useState(0);
  const [toCount, setToCount] = useState(0);
  const [attributeName, setAttributeName] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [frequency, setFrequency] = useState(false);
  const [coins, setCoins] = useState(false);
  const [showDeletePlaylistModel, setShowDeletePlaylistModel] = useState(false);
  const [showPlaylistModel, setShowPlaylistModel] = useState(false);
  const [show, setShow] = useState(false);
  const [attributesArr, setAttributesArr] = useState([]);
  const [activityObj, setActivityObj] = useState({});
  const [categoryObj, setCategoryObj] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();
  let id = useParams();
  const handleClose = () => {
    navigate(-1)
  };
  const handleSubmit = () => {
    // navigate('/home/activity/add-activity')
  };
  const getActivityDataById = async () => {
    try {
      let { data: res } = await getSingleActivity(id.id);
      if (res.success == true) {
        console.log(res.data);
        setActivityObj(res?.data);
        setFromCount(res.data.ageGroupObj.startAge);
        setToCount(res.data.ageGroupObj.endAge);
        getCategoryDataByActivityId(res.data.categoryId);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  const getCategoryDataByActivityId = async (id) => {
    try {
      let { data: res } = await getSingleCategory(id);

      if (res.success == true) {
        setCategoryObj(res?.data);

      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleAttributeAdd = async () => {
    try {
      if (name != "" && description != "" && fromCount != "" && toCount != "") {
        let obj = {
          name: name,
          description: description,
          activityId: id.id,
          ageGroupObj: {
            startAge: fromCount,
            endAge: toCount,
          },
          frequency: frequency,
          willAddCoins: coins,
        };
        console.log(obj);
        let { data: res } = await addAttributeToDb(obj);

        if (res.success == true) {
          Swal.fire(res.message);
          setCategoryObj(res?.data);
          handleClearFunction();
          navigate(-1)
        }
      } else {
        Swal.fire("Please fill all fields");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleClearFunction = () => {
    setName("");
    setDescription("");
    setFromCount(20);
    setToCount(20);
    setFrequency(false);
    setCoins(false);
  };

  const handleOnint = () => {
    getActivityDataById();
  };
  useEffect(() => {
    handleOnint();
  }, []);

  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage("");
    }
  }, [fromCount, toCount]);

  const handleFromCount = (e) => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else {
      setFromCount((prevState) => parseInt(prevState) + 1);
    }
  };
  const handleToCount = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
    }
  };
  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
    } else {
      setFromCount((prevState) => parseInt(prevState) - 1);
    }
  };

  return (
    <div>
      <div className="home-outer">
        <div className="innerPageContent">
          <div className="modelMain">
            <div style={{ width: "70%" }} className="container modelBody">
              <div className=" m-2 row flex-row">
                <h1 className="primaryText">{categoryObj?.name}</h1>
                <div className="catBackground d-flex">
                  <div className="catTitleImgBackground">
                    <img
                      style={{ display: "block", borderRadius: "20px" }}
                      className="mx-auto "
                      width={"100%"}
                      height={"100%"}
                      src={
                        activityObj.thumbnailImage
                          ? activityObj.thumbnailImage
                          : nutrition
                      }
                      alt=""
                    />
                  </div>
                  <h2 className="my-auto p-4 catTitle">{activityObj?.name}</h2>
                </div>

                <div className="row d-flex">
                  <div className="ageGroup-inner">
                    <label style={{ marginRight: "1em" }} className="formLabel">
                      Age Group:{" "}
                    </label>
                    <p>From</p>
                    <div className="agegroupBtn-outer">
                      <button className="agegroupBtn" onClick={handleFromCount}>
                        +
                      </button>
                      <input
                        className="ageCountInput"
                        value={fromCount}
                        type="number"
                        min="0"
                        onChange={(e) => setFromCount(e.target.value)}
                      />
                      <button
                        style={{ marginRight: "-2px" }}
                        className="agegroupBtn"
                        onClick={handleNegativeFromCount}
                      >
                        -
                      </button>
                    </div>
                    <p>To</p>
                    <div className="agegroupBtn-outer">
                      <button
                        className="agegroupBtn"
                        onClick={() => setToCount((prevState) => parseInt(prevState) + 1)}
                      >
                        +
                      </button>
                      <input
                        className="ageCountInput"
                        value={toCount}
                        type="number"
                        maxLength={10}
                        onChange={(e) => setToCount(e.target.value)}
                      />
                      <button
                        style={{ marginRight: "-2px" }}
                        className="agegroupBtn"
                        onClick={handleToCount}
                      >
                        -
                      </button>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      //   textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </p>
                </div>

                <div className="catName d-flex flex-row w-100">
                  <label className="my-auto p-2">Attribute Name:</label>
                  <input
                    className="catInput w-75"
                    placeholder="Type Attribute Name Here"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div className="catName d-flex flex-column">
                  <label className="my-auto p-2">Attribute Description:</label>
                  <input
                    className="catInput w-100"
                    placeholder="Type Description Here"
                    value={description}
                    style={{ width: 450, height: 80 }}
                    onChange={(e) => setDescription(e.target.value)}
                  ></input>
                </div>
                <div className="catName d-flex flex-row w-100 ">
                  <label className="my-auto p-2">Frequency:</label>
                  <div className="d-flex pl-3">
                    <div className="d-flex radioDiv ">
                      <input
                        className="attributeRadio my-auto"
                        type="radio"
                        name="frequency"
                        value={frequency}
                        checked={frequency == true}
                        onChange={() => setFrequency(true)}
                      />
                      <p className="my-auto">Yes</p>
                    </div>
                    <div className="d-flex radioDiv">
                      <input
                        className="attributeRadio my-auto"
                        type="radio"
                        name="frequency"
                        checked={frequency == false}
                        onChange={() => setFrequency(false)}
                      />
                      <p className="my-auto">No</p>
                    </div>
                  </div>
                </div>
                <div className="catName d-flex flex-row w-100 ">
                  <label className="my-auto p-2">Add Coins:</label>
                  <div className="d-flex pl-3">
                    <div className="d-flex radioDiv ">
                      <input
                        className="attributeRadio my-auto"
                        type="radio"
                        name="coin"
                        value={coins}
                        checked={coins == true}
                        onChange={() => setCoins(true)}
                      />
                      <p className="my-auto">Yes</p>
                    </div>
                    <div className="d-flex radioDiv">
                      <input
                        className="attributeRadio my-auto"
                        type="radio"
                        name="coin"
                        value={coins}
                        checked={coins == false}
                        onChange={() => setCoins(false)}
                      />
                      <p className="my-auto">No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Modal.Footer></Modal.Footer> */}
          </div>

          <div
            style={{ width: "40%" }}
            className="container mx-auto modelFooter"
          >
            <button
              className="mainButton modelBtn"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="mainButton modelBtn"
              variant="primary"
              onClick={handleAttributeAdd}
            >
              Save Attribute
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAttribute;
