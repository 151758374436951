import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
export default function PaymentStatus() {
    let navigate = useNavigate();

    // const handleNext = async () => {
    //     try {
    //         let obj = localStorage.getItem("addMoneyObj")
    //         obj = JSON.parse(obj)
    //         const { data: res } = await AddMoney(obj);
    //         if (res.success) {
    //             window.location.href = "/"
    //             // navigate("/")
    //         }
    //     } catch (error) {
    //         console.error(error)
    //         if (error?.response?.data?.message) {
    //             alert(error?.response?.data?.message)
    //         }
    //         else {
    //             alert(error?.message)
    //         }
    //     }
    // }


    // useEffect(() => {
    //     handleNext()
    // }, [])


    return (
        <div>
            <section id="sign_in_main">
                <div className="top_image">
                </div>
                <div className="container">
                    <div className="sign_in_main_div">
                        <h3 className="cokieTxt text-center">Payment Successful</h3>
                        <div  >
                            <form>
                                <div className="row">
                                    <h5 className="mt-5 cokieTxt text-center">Thank you for choosing us !!</h5>
                                    <button className="btn mt-5" style={{ backgroundColor: "#C7DD66" }}>Success</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
