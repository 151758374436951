import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import EllipseImg from "../../../Assets/images/Ellipse.png";
import editImg from "../../../Assets/images/edit.png";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import NavbarComponent from "../../../Component/Navbar";
import SidenavTabs from "../../../Component/SidenavTabs";
import { getSingleCategory } from "../../../services/category";
import {
  addNewActivity,
  deleteSingleActivity,
  getActivitiesByCategoryId,
} from "../../../services/activity";
import styles from "./activity.module.css";
import uploadIcon from "../../../Assets/images/uploadicon.png";
import Swal from "sweetalert2";
import {
  addNewSubscription,
  deleteSingleSubscription,
  getSubscriptions,
} from "../../../services/subscription";

function Subscriptions() {
  const [show, setShow] = useState(false);
  const [showActivityAddModel, setShowActivityAddModel] = useState(false);
  const [showActivityDeleteModal, setShowActivityDeleteModal] = useState(false);
  const [activityArr, setActivityArr] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [month, setmonth] = useState("");
  const [description, setDescription] = useState("");
  const [offerText, setofferText] = useState("");
  const [price, setprice] = useState("");
  const [mrp, setmrp] = useState("");
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState("");
  const [fromCount, setFromCount] = useState(0);
  const [toCount, setToCount] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [subcriptionObj, setsubcriptionObj] = useState("");

  let navigate = useNavigate();
  let id = useParams();

  const getCategoryDataById = async () => {
    try {
      let { data: res } = await getSingleCategory(id.id);
      console.log(res.data);
      if (res.success == true) {
        setCategoryObj(res?.data);
        setFromCount(res.data.ageGroupObj.startAge);
        setToCount(res.data.ageGroupObj.endAge);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  const getActivityByCategoryId = async () => {
    try {
      let { data: res } = await getSubscriptions();
      console.log(res.data);
      if (res.success == true) {
        setActivityArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleActivityAdd = async () => {
    try {
      if (
        name != "" &&
        duration != "" &&
        description != "" &&
        offerText != "" &&
        price != "" &&
        mrp != ""
      ) {
        // if (file) {
        let obj = {
          name: name,
          duration: duration, //// in months
          description: description,
          offerText: offerText,
          price: price,
          mrp: mrp,
        };
        const { data: res } = await addNewSubscription(obj);
        if (res.success) {
          console.log("this is data: ", res.data);
          Swal.fire(res.message);
          // setsubcriptionObj(res.data)
          getActivityByCategoryId();
          setShowActivityAddModel(false);
          // setFileLink(res.data.thumbnailImage);
          clearstates();
        }
        // } else {
        //   Swal.fire("Please select file");
        // }
      } else {
        Swal.fire("Please Fill all fields");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  // handleActivityAdd
  const clearstates = () => {
    setName("");
    setFromCount(0);
    setFileLink("");
    setToCount(0);
  };

  useEffect(() => {
    // getCategoryDataById();
    getActivityByCategoryId();
  }, []);

  // const getActId = (id) => {
  //     console.log(`id>>`, id)
  // }

  const handleDeleteModalTrigger = () => {
    if (selectedActivity.name) {
      setShowActivityDeleteModal(true);
    } else {
      Swal.fire("No activity selected to perform this action on");
    }
  };
  const editActHandle = () => {
    if (selectedActivity.name) {
      navigate(`/subscription/update/${selectedActivity?._id}`);
    } else {
      Swal.fire("Please select an activity to perform this action on");
    }
  };

  const handleActivityDelete = async () => {
    try {
      let { data: res } = await deleteSingleSubscription(selectedActivity?._id);
      console.log(res.data);
      if (res.success == true) {
        Swal.fire(res.message);
        getActivityByCategoryId();
        setShowActivityDeleteModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage("");
    }
  }, [fromCount, toCount]);

  const handleFromCount = (e) => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else {
      setFromCount((prevState) => parseInt(prevState) + 1);
    }
  };
  const handleToCount = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
    }
  };
  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
    } else {
      setFromCount((prevState) => parseInt(prevState) - 1);
    }
  };
  const handleMonth = (d) => {
    let m = d.slice(d.length - 2);
    setDuration(d);
    setmonth(m);
  };
  // console.log(`categoryObj`, categoryObj);

  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Subscription Name</th>
            <th>Duration</th>
            <th>description</th>
            <th>Offer</th>
            <th>Price</th>
            <th>MRP</th>
          </tr>
        </thead>
        <tbody>
          {activityArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                      setSelectedActivity(el);
                    }}
                    type="radio"
                    checked={selectedActivity._id == el._id}
                  />
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.name}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="number" name="column_1">
                    {el?.duration}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.description}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" style={{ marginLeft: "-1em" }} type="text" name="column_1">
                    {el?.offerText}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.price}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.mrp}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {categoryObj?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Subscriptions</h3>
              </div>
              <div className="col-lg-3 p-0">
                <div className="icon-outer">
                  <img
                    onClick={editActHandle}
                    className="tableIcon"
                    src={editImg}
                    alt=""
                  />
                  <img
                    onClick={() => handleDeleteModalTrigger()}
                    className="tableIcon"
                    src={GroupImg}
                    alt="delete"
                  />
                </div>
              </div>
              <Modal
                show={showActivityDeleteModal}
                onHide={() => setShowActivityDeleteModal(false)}
              >
                <div className="modelMain">
                  <Modal.Body className="modelBody ">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col d-flex ">
                          <h3 className="deletePlaylistTitle">
                            Are you sure you want to delete this video?
                          </h3>
                        </div>
                        <div className="col modelFooter mt-4">
                          <button
                            style={{ width: "160px" }}
                            className="mainButton modelBtn"
                            variant="secondary"
                            onClick={handleActivityDelete}
                          >
                            Yes, remove it
                          </button>
                          <button
                            className="mainButton modelBtn"
                            style={{
                              backgroundColor: "#AA23AD",
                              color: "#F2F4F6",
                              width: "160px",
                            }}
                            variant="primary"
                            onClick={() => setShowActivityDeleteModal(false)}
                          >
                            No, i just clicked it
                          </button>
                        </div>
                      </div>
                      <img width={124} src={pigImg} alt="" />
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                    null
                                ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
            <button
              className="addPlaylistBtn primaryBtn"
              onClick={() => setShowActivityAddModel(true)}
            >
              Add Subscription
            </button>
          </div>
          <Modal show={showActivityAddModel} onHide={() => setShow(false)}>
            <div className="modelMain">
              <Modal.Body style={{}} className="modelBody">
                <div className="row container m-2">
                  <label
                    className="primaryText"
                    style={{ marginBottom: 20, fontSize: "25px" }}
                  >
                    {categoryObj?.name} Add Subscription
                  </label>

                  <div className="col-6 catName m-0">
                    <label
                      className="primaryText"
                      style={{ marginBottom: 20, fontSize: "25px" }}
                    >
                      {categoryObj?.name}
                    </label>
                    <label>Subscription Name:</label>
                    <input
                      className="catInput"
                      placeholder="Type Subscription Name Here"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6 catName m-0 ">
                    <label>Duration: </label>
                    <input
                      className="catInput"
                      placeholder="Type Duration Here"
                      required
                      type="number"
                      value={duration}
                      maxLength="2"
                      onChange={(e) => setDuration(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6 catName m-0 ">
                    <label>Description: </label>
                    <input
                      className="catInput"
                      placeholder="Type Description Here"
                      required
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6 catName m-0 ">
                    <label>Offer: </label>
                    <input
                      className="catInput"
                      placeholder="Type Offer Here"
                      required
                      type="text"
                      maxLength={3}
                      size={3}
                      value={offerText}
                      onChange={(e) => setofferText(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6 catName m-0 ">
                    <label>Price: </label>
                    <input
                      className="catInput"
                      placeholder="Type Price Here"
                      required
                      type="number"
                      value={price}
                      onChange={(e) => setprice(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6 catName m-0 ">
                    <label>MRP: </label>
                    <input
                      className="catInput"
                      placeholder="Type MRP Here"
                      required
                      type="number"
                      value={mrp}
                      onChange={(e) => setmrp(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="modelFooter justify-content-center">
                  <button
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => {
                      setShowActivityAddModel(false);
                      setFile("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="mainButton modelBtn"
                    variant="primary"
                    onClick={handleActivityAdd}
                  >
                    Save
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
