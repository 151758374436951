import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, MenuItem } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import styles from "./ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { newcategory } from "../../../services/Ecommerce/category";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { SketchPicker } from "react-color";
import { allcategories, allsubcategories } from "../../../services/Ecommerce/category";
import { useLocation } from 'react-router-dom';



const NewCategory = () => {
  // const location = useLocation();
  // const id = location.state;
  // // let id =null;
  // // id = data;
  // console.log(id, "data passed");

  // // console.log("new cat id",newid);
  const navigate = useNavigate();

  // useEffect(() => {
  //   getallcategories(id);
  // }, [])


  // const [categoryData, SetCategoryData] = useState([]);

  // const getallcategories = async (id) => {
  //   if (id) {
  //     let { data: res } = await allsubcategories(id);
  //     console.log("all category data", res.data);
  //     SetCategoryData(res.data);
  //   } else {
  //     let { data: res } = await allcategories(id);
  //     console.log("all category data", res.data);
  //     SetCategoryData(res.data);
  //   }

  // };


  const [src, setSrc] = useState([]);
  const [tempcolor, setTempcolor] = useState([]);
  const [blockPickerColor, setBlockPickerColor] = useState([]);

  const initialValues = { categoryname: "", description: "", height: "", parentCategory: null };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form values", formValues);
  };

  const [files, setFiles] = useState({});
  console.log(files.name, "hkoo");

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const selecthandler = () => {
    setBlockPickerColor((data) => [...data, tempcolor]);
  };
  const removehandler = () => {
    setBlockPickerColor([]);
  };

  const savehandler = () => {
    console.log(blockPickerColor, "blockpickerccolor")
  }

  // const handleClose = (event, reason) => {
  //   if (reason && reason == "backdropClick" && "escapeKeyPress") return;
  //   setOpen(false);
  // };
  let catId = null;
  const submithandler = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formValues, null, 2), "Form Data befoure submit")
    setFormErrors(validate(formValues));
    catId = formValues.parentCategory;
    console.log(catId);
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("categoryname", formValues.categoryname);
    formdata.append("description", formValues.description);
    formdata.append("isBigCard", formValues.height);
    formdata.append("gradientColor", blockPickerColor);
    if (formValues.parentCategory != null) formdata.append("parentCategory", catId);
    if (
      formValues.categoryname && formValues.description
    ) {
      let { data: res } = await newcategory(formdata);
      console.log(res.success);
      if (res.success) {
        Swal.fire(res.message);
        setTimeout(() => {
          navigate("/ecommerce/category");
        }, 1500);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.categoryname) ||
      /^[ ]+$/.test(values.categoryname)
    ) {
      errors.categoryname = "Category is not valid";
    }

    if (!values.categoryname) {
      errors.categoryname = "Category is Required";
    }

    if (values.categoryname.length > 50) {
      errors.categoryname = "Character limit is 50";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }
    if (values.description.length > 150) {
      errors.description = "Character limit is 150";
    }
    return errors;
  };


  return (
    <div className={styles.container}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.categoryname}
            onChange={handleChange}
            className={styles.inputs}
            name="categoryname"
            label="Category Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.categoryname}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.description}
            onChange={handleChange}
            name="description"
            className={styles.inputs}
            label="Description *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>

        </Grid>

        <Grid item  xs={6} >
          <Grid item xs={12}>
            <TextField
              id="outlined-select"
              select
              label="Dynamic Height *"
              value={formValues.height}
              name="height"
              onChange={handleChange}
              className={styles.inputs}
              helperText="Please Select Dynamic Height"
            >
              <MenuItem value="false">No</MenuItem>

              <MenuItem value="true">Yes</MenuItem>
            </TextField>

            <small className="text-red">{formErrors.height}</small>
          </Grid>

          {/* <Grid item xs={12}>
            <TextField
              id="outlined-select"
              select
              label="Categories"
              name="parentCategory"
              onChange={handleChange}
              value={formValues.parentCategory}
              className={styles.inputs}
              helperText="Please select category"
              // placeholder="select"
            >  
            
              {categoryData?.map((option) => (
                <MenuItem value={option._id}>
                  {option.categoryname}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
        </Grid>

        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.inputs}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.inputs}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <div style={{ background: "white", padding: 20 }}>
                  {src[0] &&
                    src.map((item) => {
                      return (
                        <img
                          src={item}
                          height="110"
                          width={"100"}
                          style={{ marginRight: 20, marginBottom: 20 }}
                        />
                      );
                    })}
                </div>
              </Files>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>

          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "row",
              flex: 1,
              padding: "30px",
            }}
          >
            <SketchPicker
              color={tempcolor}
              onChange={(color) => {
                console.log(color, "kloghg");
                setTempcolor(color.hex);
              }}
            />
            <div style={{ marginLeft: "30px", flex: 1 }}>
              <p className={styles.inputs}>{tempcolor}</p>
              <TextField
                id="outlined-basic"
                value={blockPickerColor}
                className={styles.inputs}
                label="Color Varients"
                variant="outlined"
              />
              <button onClick={selecthandler} className={styles.colormodalbutton}>
                Select Color
              </button>
              <button onClick={removehandler} className={styles.colormodalbutton}>
                Reset Color
              </button>
              <button onClick={savehandler} className={styles.colormodalbutton}>
                Save Color
              </button>
            </div>
          </div>

        </Grid>


        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewCategory;


