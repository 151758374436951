import React, { useState } from 'react'
import { useEffect } from 'react';
// import { handlePayment } from '../../Services/User';
// import Swal from 'sweetalert2';
import API from '../../../axios/api';
import { useSearchParams } from 'react-router-dom';
import { createOrder } from '../../../services/Wallet';
import Loader from '../../../Component/Loader';

export default function TopUpPayment() {
    
    const [searchParams] = useSearchParams();

    const [axisPaymentObj, setAxisPaymentObj] = useState({});



    const handleCreateOrder = async () => {
        try {
            let res = await createOrder(searchParams.get('token'));
            setAxisPaymentObj(res.data.axisPayment);
            console.log(JSON.stringify(res.data, null, 2), "Axis Data ");
            if (res.data) {
                let form = document.getElementById("form");
                form.submit();
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message)
                alert(err?.response?.data?.message)
            } else {
                console.log(err)
                alert(err)
            }
        }
    }
    useEffect(() => {
        handleCreateOrder()
    }, [])

    // https://sandbox-axispg.freecharge.in/payment/v1/checkout // Dev Env
    // https://secure-axispg.freecharge.in/payment/v1/checkout // Production Env
    return (
        <>
            <div>
                <Loader show={true} />
                {axisPaymentObj ?
              <>
                <form id="form" style={{ display: "none" }} action="https://sandbox-axispg.freecharge.in/payment/v1/checkout" method="POST" encType="application/x-www-form-urlencoded">
                    <input style={{ display: "none" }} type="text" name="merchantId" value={axisPaymentObj.merchantId}/>
                    <input style={{ display: "none" }} type="text" name="callbackUrl" value={axisPaymentObj.callbackUrl}/>
                    <input style={{ display: "none" }} type="text" name="merchantTxnId" value={axisPaymentObj.merchantTxnId}/>
                    <input style={{ display: "none" }} type="text" name="merchantTxnAmount" value={axisPaymentObj.merchantTxnAmount}/>
                    <input style={{ display: "none" }} type="text" name="Currency" value={axisPaymentObj.Currency}/>
                    <input style={{ display: "none" }} type="text" name="timestamp" value={axisPaymentObj.timestamp}/>
                    <input style={{ display: "none" }} type="text" name="Signature" value={axisPaymentObj.Signature}/>
                    <input style={{ display: "none" }} type="text" name="customerId" value={axisPaymentObj.customerId}/>
                    <input style={{ display: "none" }} type="text" name="customerName" value={axisPaymentObj.customerName}/>
                    <input style={{ display: "none" }} type="text" name="customerEmailId" value={axisPaymentObj.customerEmailId}/>
                    <input style={{ display: "none" }} type="text" name="customerMobileNo" value={axisPaymentObj.customerMobileNo}/>
                    <input style={{ display: "none" }} type="text" name="customerPIN" value={axisPaymentObj.customerPIN}/>
                </form>
                </>
                :
                ''
                }
            </div>
        </>

    )
}