import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";

import styles from "../ecommerce/ecommerce.module.css";
import { useNavigate } from "react-router";
import { addTaxDetails } from "../../../services/GstDetails/gstDetails";

const TaxForm = () => {
  const navigate = useNavigate();
  const initialValues = {
    applicableFrom: "",
    isReverseCharged: "",
    eligbleInputCredit: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (
      formValues.applicableFrom &&
      formValues.isReverseCharged &&
      formValues.eligbleInputCredit
    ) {
      let { data: res } = await addTaxDetails(formValues);
      console.log("data", res);
      if (res.success == true) {
        setTimeout(() => {
          alert("Tax Details Added Successfully");
          navigate(`/tax-details`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.applicableFrom) {
      errors.applicableFrom = "Applicable from is Required";
    }

    if (!values.isReverseCharged) {
      errors.isReverseCharged = "Reverse Charged is Required";
    }
    if (!values.eligbleInputCredit) {
      errors.eligbleInputCredit = "Is Non GstGoods is Required";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
            <label>Applicable from *</label>
          <input
            type="date"
            name="applicableFrom"
            value={formValues.applicableFrom}
            onChange={handleChange}
            label="Applicable From*"
          />
          <small className="text-red">{formErrors.applicableFrom}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            id="outlined-basic"
            value={formValues.isReverseCharged}
            onChange={handleChange}
            className={styles.inputs}
            label="Reverse Charged*"
            variant="outlined"
            name="isReverseCharged"
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </TextField>
          <p className="text-red">{formErrors.isReverseCharged}</p>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.eligbleInputCredit}
            onChange={handleChange}
            className={styles.inputs}
            label="Eligble Input Credit*"
            variant="outlined"
            name="eligbleInputCredit"
          />
          <p className="text-red">{formErrors.eligbleInputCredit}</p>
        </Grid>

        <Grid item xs={6}>
          <TextareaAutosize
            minRows={6}
            id="outlined-basic"
            value={formValues.description}
            name="description"
            onChange={handleChange}
            className={styles.textarea}
            placeholder="Description"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TaxForm;
