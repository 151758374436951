import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import styles from "./activity.module.css";
import Swal from "sweetalert2";
import {
    getAllImportantLink,
    addImportantLink,
    deleteImportantLink
} from "../../../services/importantLinks";

function ImportantLinks() {
  const [showImportantLinkAddModel, setShowImportantLinkAddModel] = useState(false);
  const [showImportantLinkDeleteModal, setShowImportantLinkDeleteModal] = useState(false);
  const [ImportantLinkArr, setImportantLinkArr] = useState([]);
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [selectedImportantLink, setSelectedImportantLink] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableInput, setDisableInput] = useState(false);

  let navigate = useNavigate();
  let id = useParams();

  const getAll = async () => {
    try {
      let { data: res } = await getAllImportantLink();
      console.log(res.data);
      if (res.success == true) {
        setImportantLinkArr(res?.data);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleImportantLinkAdd = async () => {
    try {
      if (text == "") {
        Swal.fire("Link Name Cannot Be Empty");
        return;
      }
      else if (link == "Link Cannot Be Empty") {
        Swal.fire("");
        return;
      } else {
        let obj = {
          text,
          link,
        };
        const { data: res } = await addImportantLink(obj);
        if (res.success) {
          console.log("this is data: ", res.data);
          Swal.fire(res.message);
        //   setsubcriptionObj(res.data)
          getAll();
          setShowImportantLinkAddModel(false);
          clearstates();
        } else {
          Swal.fire("Please select file");
        }
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  // handleActivityAdd
  const clearstates = () => {
    setText("");
    setLink("")
  };

  useEffect(() => {
    getAll();
  }, []);

  const handleDeleteModalTrigger = () => {
    if (selectedImportantLink._id) {
      setShowImportantLinkDeleteModal(true);
    } else {
      Swal.fire("No LInk selected to perform this action on");
    }
  };

  const handleImportantLinkDelete = async () => {
    try {
      let { data: res } = await deleteImportantLink(selectedImportantLink?._id);
      console.log(res.data);
      if (res.success == true) {
        Swal.fire(res.message);
        getAll();
        setShowImportantLinkDeleteModal();
      }
    } catch (error) {
      console.error(error);
    }
  };


  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Important Link Text</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ImportantLinkArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex flex-row">
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                        console.log(el);
                        setSelectedImportantLink(el);
                    }}
                    type="radio"
                    checked={selectedImportantLink._id == el._id}
                  />
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.name}
                  </p>
                </div>
              </td>
              <td>
                {/* <div className={`${styles.tableImgOuter}`}
                    styles={{width: '10rem', paddingRight: '5rem'}}
                > */}
                <button
                    className="addPlaylistBtn primaryBtn"
                    style={{ marginRight: '2rem' }}
                      onClick={() => openInNewTab(el?.link)}
                    >
                    Click to Login 
                </button>
                {/* </div> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}></h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all ImportantLinks</h3>
              </div>
              <div className="col-lg-3 p-0">
                <div className="icon-outer">
                  <img
                    onClick={() => handleDeleteModalTrigger()}
                    className="tableIcon"
                    src={GroupImg}
                    alt="delete"
                  />
                </div>
              </div>
              <Modal
                show={showImportantLinkDeleteModal}
                onHide={() => setShowImportantLinkDeleteModal(false)}
              >
                <div className="modelMain">
                  <Modal.Body className="modelBody ">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col d-flex ">
                          <h3 className="deletePlaylistTitle">
                            Are you sure you want to delete this ImportantLink?
                          </h3>
                        </div>
                        <div className="col modelFooter mt-4">
                          <button
                            style={{ width: "160px" }}
                            className="mainButton modelBtn"
                            variant="secondary"
                            onClick={() => handleImportantLinkDelete()}
                          >
                            Yes, remove it
                          </button>
                          <button
                            className="mainButton modelBtn"
                            style={{
                              backgroundColor: "#AA23AD",
                              color: "#F2F4F6",
                              width: "160px",
                            }}
                            variant="primary"
                            onClick={() =>
                              setShowImportantLinkDeleteModal(false)
                            }
                          >
                            No, i just clicked it
                          </button>
                        </div>
                      </div>
                      <img width={124} src={pigImg} alt="" />
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                    null
                                ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
            <button
              className="addPlaylistBtn primaryBtn"
              onClick={() => setShowImportantLinkAddModel(true)}
            >
              Add Important Link
            </button>
          </div>

          <Modal
            show={showImportantLinkAddModel}
            onHide={() => setShowImportantLinkAddModel(false)}
          >
            <div className="modelMain  modal-dialog-centered ">
              <Modal.Body className="modelBody ">
                <div className="container m-2">
                  <div className="catName m-0">
                    <label>Add Links Name</label>
                    <input
                      className="catInput"
                      placeholder="Add Name here"
                      required
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    ></input>
                  </div>
                  <div className="catName m-0">
                    <label>Add Links</label>
                    <input
                      className="catInput"
                      placeholder="Add Link here"
                      required
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    ></input>
                  </div>

                  {/* <div className="ageGroup">
                    <label>Age Group: </label>

                  </div> */}
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </p>
                </div>
                <div className="modelFooter justify-content-center">
                  <button
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => {
                      setShowImportantLinkAddModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="mainButton modelBtn "
                    variant="primary"
                    onClick={() => handleImportantLinkAdd()}
                  >
                    Save
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ImportantLinks;

