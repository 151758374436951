import React, { useState } from 'react'
import { useEffect } from 'react';
// import { handlePayment } from '../../Services/User';
// import Swal from 'sweetalert2';
import API from '../../../axios/api';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../../Component/Loader';

export default function Payments() {


    const [auth_token, setAuth_token] = useState("");
    const [client, setClient] = useState("");
    const [order_id, setOrder_id] = useState("");
    const [success_callback, setSuccess_callback] = useState("");
    const [error_callback, setError_callback] = useState("");
    const [additionalParams, setadditionalParams] = useState("");
    const [favcyPaymentResponse, setFavcyPaymentResponse] = useState("");
    const [redirect, setRedirect] = useState("0");
    const [credit_to, setCredit_to] = useState("0");
    const [affiliate_id, setAffiliate_id] = useState("")
    const [amount_in_100, setAmount_in_100] = useState("")
    const [provider, setProvider] = useState("")
    const [currency_type, setCurrency_type] = useState("")

    const [searchParams, setSearchParams] = useSearchParams();



    useEffect(() => {
        console.log(searchParams);
        let form = document.getElementById("form");
        form.submit()
    }, [])
    // https://sandbox-axispg.freecharge.in/payment/v1/checkout // Dev Env
    // https://secure-axispg.freecharge.in/payment/v1/checkout // Production Env

    return (
        <>
            <div>
                <Loader show={true} />
              <>
              {/* style={{ display: "none" }} */}
                <form id="form"  action="https://sandbox-axispg.freecharge.in/payment/v1/checkout" method="POST" encType="application/x-www-form-urlencoded">
                    <input  type="text" name="merchantId" value={searchParams.get('mid')}/>
                    <input  type="text" name="callbackUrl" value={searchParams.get('callbackUrl')}/>
                    <input  type="text" name="merchantTxnId" value={searchParams.get('merchantTxnId')}/>
                    <input  type="text" name="merchantTxnAmount" value={searchParams.get('merchantTxnAmount')}/>
                    <input  type="text" name="Currency" value='INR'/>
                    <input  type="text" name="timestamp" value={searchParams.get('timestamp')}/>
                    <input  type="text" name="Signature" value={searchParams.get('Signature')}/>
                    <input  type="text" name="customerId" value={searchParams.get('customerId')}/>
                    <input  type="text" name="customerName" value={searchParams.get('customerName')}/>
                    <input  type="text" name="customerEmailId" value={searchParams.get('customerEmailId')}/>
                    <input  type="text" name="customerMobileNo" value={searchParams.get('customerMobileNo')}/>
                    <input  type="text" name="customerPIN" value={searchParams.get('customerPIN')}/>
                </form>
                </>
            </div>
        </>
        // <div >
        //     <Loader show={true} />
        //     <form id="form" style={{ display: "none" }} action="https://www.favcy.com/payments/pay" method="POST">
        //         <input type="text" style={{ display: "none" }} name="additional_params" value='{"brand_name": "Good Good Piggy","description": "You are paying for Good Good Piggy"}' />
        //         <input type="text" style={{ display: "none" }} name="auth_token" value={searchParams.get('favcy_token')} onChange={(e) => setAuth_token(e.target.value)} placeholder="Auth Token" />
        //         <input type="text" style={{ display: "none" }} name="client" value={'ggp'} onChange={(e) => setClient(e.target.value)} placeholder="Client" />
        //         <input type="text" style={{ display: "none" }} name="order_id" value={searchParams.get('order_id')} onChange={(e) => setOrder_id(e.target.value)} placeholder="Order_Id" />
        //         <input type="text" style={{ display: "none" }} name="success_callback" value={`${API.PAYMENT_URL}/bless-payment-success/${encodeURIComponent(searchParams.get('kid_id'))}/${encodeURIComponent(searchParams.get('amount'))}/${encodeURIComponent(searchParams.get('description'))}/${encodeURIComponent(searchParams.get('email'))}/${encodeURIComponent(searchParams.get('phone'))}/${encodeURIComponent(searchParams.get('name'))}`} onChange={(e) => setSuccess_callback(e.target.value)} placeholder="Success Callback" />
        //         <input type="text" style={{ display: "none" }} name="error_callback" value={`${API.PAYMENT_URL}/payment-error`} onChange={(e) => setError_callback(e.target.value)} placeholder="Error Callback" />
        //         <input type="text" style={{ display: "none" }} name="redirect" value={'0'} onChange={(e) => setRedirect(e.target.value)} placeholder="Error Callback" />
        //         <input type="text" style={{ display: "none" }} name="provider" value={'razorpay'} onChange={(e) => setProvider(e.target.value)} placeholder="Provider" />
        //         <input type="text" style={{ display: "none" }} name="currency_type" value={'INR'} onChange={(e) => setCurrency_type(e.target.value)} placeholder="currency_type" />
        //         <input type="text" style={{ display: "none" }} name="amount_in_100" value={searchParams.get('amount_in_100')} onChange={(e) => setAmount_in_100(e.target.value)} placeholder="amount_in_100" />
        //         {/* ///staging */}
        //         {/* <input type="text" name="affiliate_id" value={'1035'} onChange={(e) => setAmount_in_100(e.target.value)} placeholder="amount_in_100" /> */}
        //         {/* production */}
        //         <input type="text" style={{ display: "none" }} name="affiliate_id" value={'245'} onChange={(e) => setAmount_in_100(e.target.value)} placeholder="amount_in_100" />
        //         <input type="text" style={{ display: "none" }} name="credit_to" value={'user'} onChange={(e) => setAmount_in_100(e.target.value)} placeholder="amount_in_100" />
        //     </form>
        // </div>
    )
}