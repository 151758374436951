import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import styles from "./activity.module.css";
import Swal from "sweetalert2";
import {
  addNotification,
  deleteNotification,
  getAllNotifications,
} from "../../../services/notification";
import { addBlessings } from "../../../services/Blessings";
import { useSearchParams } from "react-router-dom";
import { createBlessingOrder, createOrder } from "../../../services/Wallet";

export default function Blessings() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [customerPin, setCustomerPin] = useState("");
  // const [paymentObj, setPaymentObj] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate()

  const handleBlessingAdd = async (paymentObj) => {
    try {
      navigation(`/blessing_payment?mid=${paymentObj.merchantId}&callbackUrl=${paymentObj.callbackUrl}&merchantTxnId=${paymentObj.merchantTxnId}&merchantTxnAmount=${paymentObj.merchantTxnAmount}&timestamp=${paymentObj.timestamp}&Signature=${paymentObj.Signature}&customerId=${paymentObj.customerId}&customerName=${paymentObj.customerName}&customerEmailId=${paymentObj.customerEmailId}&customerMobileNo=${paymentObj.customerMobileNo}&customerPIN=${paymentObj.customerPIN}&description=${description}&email=${email}&phone=${phone}&name=${name}`)
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };


  const handleCreateOrderAdd = async () => {
    try {
      if (name == "") {
        Swal.fire("Name Cannot Be Empty");
        return
      }
      else if (email == "") {
        Swal.fire("Email");
        return
      } else if (phone == "") {
        Swal.fire("Phone cannot be empty");
        return
      } else if (description == "") {
        Swal.fire("Description cannot be empty");
        return
      } else if (amount == "") {
        Swal.fire("Amount cannot be empty");
        return
      }
      let token = searchParams.get("token");
      let obj = {
        merchantTxnAmount: amount,
        customerName: name,
        customerEmailId: email,
        customerMobileNo: phone, 
        customerPIN: customerPin,
        description: description
      };
        const res =  await createBlessingOrder(token, obj);
        console.log(res)
        if(res.status === 200 || res.success === true){
          // setPaymentObj(res.axisPayment);
          // console.log()
          console.log(res, "Blessing Order Data");
          handleBlessingAdd(res.data.axisPayment);
        }
        console.log("order created")

      } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  useEffect(() => {
  }, [])

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "2rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}></h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>Blessings</h3>
              </div>

              <div class="form-group mt-2">
                <label for="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="text"
                  aria-describedby="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Phone</label>
                <input
                  type="tel"
                  class="form-control"
                  id="text"
                  aria-describedby="name"
                  placeholder="Enter Phone"
                  value={phone}
                  maxLength={10}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Description</label>
                <input
                  type="text"
                  class="form-control"
                  id="text"
                  aria-describedby="name"
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">User Pin Code</label>
                <input
                  type="number"
                  class="form-control"
                  id="text"
                  aria-describedby="name"
                  placeholder="Enter Your Address Pin code..."
                  value={customerPin}
                  onChange={(e) => setCustomerPin(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Amount</label>
                <input
                  type="number"
                  class="form-control"
                  id="text"
                  aria-describedby="name"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
               <input type="text" style={{ display: "none"}} name="additional_params" value='{"brand_name": "Good Good Piggy","description": "You are paying for Good Good Piggy"}' />
              </div>

              <div class="form-group form-check mt-2"></div>
              <button
                style={{ float: "left", padding: "1em 2em" }}
                onClick={() => handleCreateOrderAdd()}
                class="addPlaylistBtn primaryBlessingsBtn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
