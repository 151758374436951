import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import styles from "./ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { SketchPicker } from "react-color";
import { addproduct } from "../../../services/Ecommerce/Product";
import {
  allcategories,
  allsubcategories,
  allsubcategoriesByCategoties,
} from "../../../services/Ecommerce/category";
import { useNavigate } from "react-router";
import Select from "react-select";
import { categoryList, newxcat, myxcat } from "./categorydata";

const AddProduct = () => {
  const navigate = useNavigate();
  const [allcategory, setAllcategory] = useState([]);
  const [category, setCategory] = useState("");
  const [src, setSrc] = useState();
  const [blockPickerColor, setBlockPickerColor] = useState([]);
  const [tempcolor, setTempcolor] = useState([]);
  const [files, setFiles] = useState({});
  const [open, setOpen] = React.useState(false);

  const [cat, setCat] = useState(myxcat);

  const initialValues = {
    // categories: ,
    productname: "",
    category: [],
    description: "",
    returnpolicy: "",
    madein: "",
    return: "",
    agefrom: "",
    ageto: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [fetchCatId, setFetchCatId] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const [toCount, setToCount] = useState(14);
  const [fromCount, setFromCount] = useState(4);

  const getallcategories = async () => {
    let { data: res } = await allcategories();
    // console.log("category response", res.data);
    res?.data?.forEach((element) => {
      element.label = element.categoryname;
      element.value = element._id;
      // console.log((element.label = element.categoryname));
      console.log(element);
    });
    setAllcategory(res.data);
  };

  useEffect(() => {
    getallcategories();
  }, [category]);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyPress") return;
    setOpen(false);
  };

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    console.log("from", fromCount, "to", toCount);
    formValues.agefrom = fromCount;
    formValues.ageto = toCount;
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("product_name", formValues.productname);
    formdata.append("description", formValues.description);
    formdata.append(
      "category",
      formValues.category.map((x) => x._id)
    );
    formdata.append("selectedSubCat", selectedSubCat.map((x) => x._id));
    formdata.append("return_policy", formValues.returnpolicy);
    formdata.append("return", formValues.return);
    formdata.append("agefrom", formValues.agefrom);
    formdata.append("ageto", formValues.ageto);

    if (formValues.productname && formValues.description) {
      console.log("API HIT", formValues);
      let { data: res } = await addproduct(formdata);
      console.log("data", res);
      if (res.status === "success") {
        setTimeout(() => {
          alert("Product Added Successfully");
          navigate(`/ecommerce/product/${res.data._id}/variants`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.productname) {
      errors.productname = "Product Name is Required";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }
    if (values.description.length > 500) {
      errors.description = "Can't Add more than 500 Charatcers";
    }
    if (!values.category) {
      errors.category = "Please select Product Category";
    }
    if (!values.returnpolicy) {
      errors.returnpolicy = "Please add Return Policy";
    }
    if (!values.return) {
      errors.return = "Please select True or False";
    }
    if (!values.madein) {
      errors.madein = "Please enter country of origin";
    }
    if (fromCount < 4) {
      errors.fromCount = "Min age is 4";
    }
    if (toCount > 14) {
      errors.toCount = "Max age is 14";
    }
    return errors;
  };

  const selecthandler = () => {
    setBlockPickerColor((data) => [...data, tempcolor]);
  };
  const removehandler = () => {
    setBlockPickerColor([]);
  };

  const handleMultiple = async (name, data) => {
    let fetchCatId = "";
    if (name === "category") {
      setSelectedSubCat([])
      setFormValues({ ...formValues, [name]: data });
      console.log("cat data", data);
      fetchCatId = data.map((x) => x._id);
      if (fetchCatId) {
        console.log(fetchCatId, 'fetch id')

        let { data: res } = await allsubcategoriesByCategoties(fetchCatId);
        console.log(res.data, "sub cat fetched");
        res?.data?.forEach((element) => {
          element.label = element.categoryname;
          element.value = element._id;
          console.log(element);
        });
        setSubCategory(res.data);
      }
    } else {
      // console.log("running me");
      setSelectedSubCat(data);   
      // console.log('data', data)
    }
  };
  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="productname"
            value={formValues.productname}
            onChange={handleChange}
            className={styles.inputs}
            label="Product Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.productname}</small>
        </Grid>
        <Grid item xs={6}>
          <Select
            isMulti
            onChange={(data) => {
              handleMultiple("category", data);
            }}
            options={allcategory}
            value={formValues.category}
          />
          <br />
          <Select
            isMulti
            onChange={(data) => {
              handleMultiple("subCategory", data);
            }}
            options={subCategory}
            value={selectedSubCat}
          />
       
          
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-select"
            select
            label="Return & Exchange *"
            value={formValues.return}
            name="return"
            onChange={handleChange}
            className={styles.inputs}
          >
            <MenuItem value="false">No</MenuItem>

            <MenuItem value="true">Yes</MenuItem>
          </TextField>
          <small className="text-red">{formErrors.return}</small>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-select"
            select
            label="Country Of Origin *"
            value={formValues.madein}
            name="madein"
            onChange={handleChange}
            className={styles.inputs}
          >
            <MenuItem value="India">India</MenuItem>
          </TextField>
          <small className="text-red">{formErrors.madein}</small>
        </Grid>
        <Grid item xs={6}>
          <label>Age Group: </label>
          <div>
            <p>From</p>
            <div className={styles.age}>
              <button
                className={styles.agebutton}
                onClick={() =>
                  setFromCount((prevState) => parseInt(prevState) - 1)
                }
              >
                -
              </button>
              <p className={styles.agep}>{fromCount}</p>
              <button
                className={styles.agebutton}
                onClick={() =>
                  setFromCount((prevState) => parseInt(prevState) + 1)
                }
              >
                +
              </button>
              <small className="text-red" style={{ paddingLeft: "10px" }}>
                {formErrors.fromCount}
              </small>
            </div>

            <p>To</p>
            <div className={styles.age}>
              <button
                className={styles.agebutton}
                onClick={() =>
                  setToCount((prevState) => parseInt(prevState) - 1)
                }
              >
                -
              </button>
              <p className={styles.agep}>{toCount}</p>
              <button
                className={styles.agebutton}
                onClick={() =>
                  setToCount((prevState) => parseInt(prevState) + 1)
                }
              >
                +
              </button>
              <small className="text-red" style={{ paddingLeft: "10px" }}>
                {formErrors.toCount}
              </small>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={["image/png", ".pdf", "audio/*"]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={src} height="170" width="170" />
              </Files>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextareaAutosize
            minRows={6}
            id="outlined-basic"
            value={formValues.description}
            name="description"
            onChange={handleChange}
            className={styles.textarea}
            placeholder="Description *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>
        </Grid>

        <Grid item xs={6}>
          <TextareaAutosize
            minRows={6}
            id="outlined-basic"
            value={formValues.returnpolicy}
            onChange={handleChange}
            name="returnpolicy"
            className={styles.textarea}
            placeholder="Return & Policy"
            variant="outlined"
          />
          <small className="text-red">{formErrors.returnpolicy}</small>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
      <Modal
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: "15%", marginLeft: "40%", width: "30%" }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "row",
            flex: 1,
            padding: "30px",
          }}
        >
          <SketchPicker
            color={tempcolor}
            onChange={(color) => {
              console.log(color, "kloghg");
              setTempcolor(color.hex);
            }}
          />
          <div style={{ marginLeft: "30px", flex: 1 }}>
            <p className={styles.inputs}>{tempcolor}</p>
            <TextField
              id="outlined-basic"
              value={blockPickerColor}
              className={styles.inputs}
              label="Color Varients"
              variant="outlined"
            />
            <button onClick={selecthandler} className={styles.colormodalbutton}>
              Select
            </button>
            <button onClick={removehandler} className={styles.colormodalbutton}>
              Remove
            </button>
            <button onClick={handleClose} className={styles.colormodalbutton}>
              {" "}
              Save Change
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddProduct;
