import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/playlistvideos`;

export const getAllVideos = async (id) => {
  return await axios.get(BASE_LINK + `/getByPlaylistId/${id}/`);
};

export const getSingleVideo = async (id) => {
  return await axios.get(BASE_LINK + `/getById/${id}/`);
};
export const addNewVideo = async (formData) => {
  return await axios.post(BASE_LINK + `/`, formData);
};
export const deleteSingleVideo = async (id) => {
  return await axios.delete(BASE_LINK + `/deleteById/${id}/`);
};
export const updateSingleVideo = async (id, formData) => {
  return await axios.patch(BASE_LINK + `/updateById/${id}/`, formData);
};
