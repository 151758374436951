import React, { useState, useEffect } from "react";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import styles from "../ecommerce/ecommerce.module.css";
import { useNavigate } from "react-router";
import {
  addTaxPrefix,
  getUniverseData,
} from "../../../services/GstDetails/gstDetails";

const PrefixForm = () => {
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    type: "",
    method: "",
    values: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectData, SetSelectData] = useState([]);
  const [selectName, SetSelectName] = useState([]);
  const [selectMethod, SetSelectMethod] = useState([]);
  const [showMethod, setShowMethod] = useState(false);

  const getPrefixSelectData = async () => {
    let { data: res } = await getUniverseData("entityCode=taxPrefix-type&&status=true");
    SetSelectData(res.data);
  };

  const getPrefixNameData = async () => {
    let { data: res } = await getUniverseData("entityCode=taxPrefix-name&&status=true");
    SetSelectName(res.data);
  };

  const getPrefixMethodData = async () => {
    let { data: res } = await getUniverseData("entityCode=taxPrefix-method&&status=true");
    SetSelectMethod(res.data);
  };

  useEffect(() => {
    getPrefixSelectData();
    getPrefixNameData();
    getPrefixMethodData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if(name === "type"){
      setFormValues({ ...formValues, [name]: value._id });
      console.log(value, "Selectd Value")
          if(e.target.value.valueCode == "transactionType"){
            setShowMethod(true);
          }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    console.log(formValues, "Form Values");
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("name", formValues.name);
    formdata.append("description", formValues.description);
    formdata.append("type", formValues.type);
    formdata.append("method", formValues.method);
    formdata.append("values", formValues.values);
    if (
      formValues.name &&
      formValues.type &&
      formValues.values
    ) {
      console.log(formValues, "formValues");
      let { data: res } = await addTaxPrefix(formValues);
      console.log("data", res);
      if (res.success == true) {
        setTimeout(() => {
          alert("Prefix Added Successfully");
          navigate(`/taxprefix-table`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (!values.values) {
      errors.values = "values is Required";
    }
    if (!values.type) {
      errors.type = "Please select Prefix type";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            className={styles.inputs}
            label="Name *"
            variant="outlined"
            select
            helperText="Please select a Type"
          >
              {selectName?.map((option) => (
              <MenuItem key={option._id} value={option.valueCode}>
                {option.valueDesc}
              </MenuItem>
            ))}
            </TextField>
          <small className="text-red">{formErrors.name}</small>
        </Grid>

       
        <Grid item xs={6}>
          <TextField
            id="outlined-select"
            select
            label="Prefix Type *"
            // value={formValues.type}
            name="type"
            onChange={handleChange}
            helperText="Please select a Type"
          >
            {selectData?.map((option) => (
              <MenuItem key={option._id} value={option} selected={formValues.type === option._id ? true : false}>
                {option.valueDesc}
              </MenuItem>
            ))}
          </TextField>
          <small className="text-red">{formErrors.type}</small>
        </Grid>

        {showMethod ? (
          <Grid item xs={6}>
          <TextField
          select
            id="outlined-basic"
            value={formValues.method}
            onChange={handleChange}
            className={styles.inputs}
            label="Method*"
            variant="outlined"
            name="method"
          >
              {selectMethod?.map((option) => (
              <MenuItem key={option._id} value={option.valueCode}>
                {option.valueDesc}
              </MenuItem>
            ))}
            </TextField>
          <p className="text-red">{formErrors.method}</p>
        </Grid>
) : ""}
        

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.values}
            onChange={handleChange}
            className={styles.inputs}
            label="Value*"
            variant="outlined"
            name="values"
          />
          <p className="text-red">{formErrors.values}</p>
        </Grid>

        <Grid item xs={6}>
          <TextareaAutosize
            minRows={6}
            id="outlined-basic"
            value={formValues.description}
            name="description"
            onChange={handleChange}
            className={styles.textarea}
            placeholder="Description *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrefixForm;
