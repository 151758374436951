import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.Ecommerce}`;


export const addproduct = async (formData) => {
    console.log(formData, 'form data')
    return await axios.post(`${BASE_LINK}/shop/product/create`, formData);
};

export const updateProduct = async (id, formData) => {
    console.log("id", id)
    return await axios.post(`${BASE_LINK}/shop/product//updateById/${id}`, formData);
};

export const addproductVariants = async (formData) => {
    return await axios.post(`${BASE_LINK}/shop/product/variants/create`, formData);
};

export const getallproduct = async () => {
    return await axios.get(`${BASE_LINK}/shop/product`);
};

export const getProductVariantData = async (id) => {
    return await axios.get(`${BASE_LINK}/shop/product/variants/foundByProductID/${id}`);
};

export const productStatus = async (id) => {
    return await axios.get(`${BASE_LINK}/shop/product/updateStatus/${id}`);
};

export const productDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/shop/product/deleteById/${id}`);
};

export const productVariantDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/shop/product/variants/deleteById/${id}`);
};

export const productVariantSetDefault = async (obj) => {
    console.log(obj)
    return await axios.post(`${BASE_LINK}/shop/product/variants/setDefault?`, obj);
};

export const getProductData = async (id) => {
    return await axios.get(`${BASE_LINK}/shop/product/findById/${id}`);
};