import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { TextField, Grid, MenuItem, colors } from "@mui/material";
import styles from "./ecommerce.module.css";
import Variants from "./Variants";
import {
  addproductVariants,
  getProductVariantData,
  productVariantDelete,
  productVariantSetDefault,
} from "../../../services/Ecommerce/Product";
import { MdDelete } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/mascot.png";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Select from "react-select";
import { sizes } from "./categorydata";

const AddProductVariants = () => {
  const { id } = useParams();

  const initValues = {
    product_Id: id,
    delivery_charges: "",
    estimated_delivery_in_days: "",
    mrp_price: "",
    selling_price: "",
    inventoryQuantity: "",
    reserveQuantity: "",
    color: "",
    quantity: "",
    size: [],
  };

  const [formValues, setformValues] = useState(initValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [checked, setchecked] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target; 
    setformValues({ ...formValues, [name]: value });
  };

  const submithandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleSubmit = async () => {
      const formDetails = { ...formValues };
      console.log(formDetails, "formDetails errors 0");
      let { data: res } = await addproductVariants(formDetails);
      console.log("response add var", res);
      if (res.success) {
        getVariantsData();
        Swal.fire(res.message);
      }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.delivery_charges) ||
      /^[ ]+$/.test(values.delivery_charges)
    ) {
      errors.delivery_charges = "Delivery Charges is not valid";
    }

    if (!values.delivery_charges) {
      errors.delivery_charges = "Delivery Charges is Required";
    }

    if (!values.estimated_delivery_in_days) {
      errors.estimated_delivery_in_days = "Estimated Delivery is Required";
    }

    if (!values.mrp_price) {
      errors.mrp_price = "MRP Price is Required";
    }

    if (!values.selling_price) {
      errors.selling_price = "Selling Price is Required";
    }
    if (!values.inventoryQuantity) {
      errors.inventoryQuantity = "Inventory Quantity is Required";
    }

    if (!values.reserveQuantity) {
      errors.reserveQuantity = "Reserve Quantity is Required";
    }
    return errors;
  };

  const [variantsData, setVariantsData] = useState([]);
  const [checkdelete, setcheckdelete] = useState(true);

  const getVariantsData = async () => {
    let { data: res } = await getProductVariantData(id);
    console.log("data", res.data);
    setVariantsData(res.data);
  };

  useEffect(() => {
    getVariantsData();
  }, [checkdelete]);

  const delModelHandle = async (id) => {
    console.log("Delete", id);
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const setvariantDefault = async (variantId) => {
    const obj = {
      productId: id,
      variantId: variantId,
    };
    let res = await productVariantSetDefault(obj);
  };

  const deleteParent = async (id) => {
    try {
      let { status: status } = await productVariantDelete(id);
      if (status === 200) {
        console.log("deleted Category Done", status);
        Swal.fire("Variant Deleted Succeessfully");
        setcheckdelete(!checkdelete);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const resetColor = () => {
    setformValues({ ...formValues, color: "" });
  };

  const handleMultiple = async (name, data) => {
    setformValues({ ...formValues, [name]: data });
    console.log("cat data", data);
  };

  useEffect(() => {
    console.log("i am hitting again baby", Object.keys(formErrors), isSubmit);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("runn");
      handleSubmit();
    }
  }, [formValues, formErrors]);

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.delivery_charges}
              onChange={handleChange}
              className={styles.inputs}
              label="Delivery Charges *"
              variant="outlined"
              name="delivery_charges"
            />
            <p className="text-red">{formErrors.delivery_charges}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.estimated_delivery_in_days}
              onChange={handleChange}
              className={styles.inputs}
              label="Estimation Delivery *"
              variant="outlined"
              name="estimated_delivery_in_days"
            />
            <p className="text-red">{formErrors.estimated_delivery_in_days}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.mrp_price}
              onChange={handleChange}
              className={styles.inputs}
              label="MRP *"
              variant="outlined"
              name="mrp_price"
            />
            <p className="text-red">{formErrors.mrp_price}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.selling_price}
              onChange={handleChange}
              className={styles.inputs}
              label="Selling Price *"
              variant="outlined"
              name="selling_price"
            />
            <p className="text-red">{formErrors.selling_price}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.inventoryQuantity}
              onChange={handleChange}
              className={styles.inputs}
              label="Inventory Quantity *"
              variant="outlined"
              name="inventoryQuantity"
            />
            <p className="text-red">{formErrors.inventoryQuantity}</p>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.reserveQuantity}
              onChange={handleChange}
              className={styles.inputs}
              label="Reserve Quantity *"
              variant="outlined"
              name="reserveQuantity"
            />
            <p className="text-red">{formErrors.reserveQuantity}</p>
          </Grid>

          {/*  Variants */}

          <p className={styles.variantHeader}>Variant Data</p>
          <Grid width={"45%"} style={{ marginLeft: "25px", marginTop: "5px" }}>
            <label
              style={{ padding: "5px", paddingLeft: "0px", display: "block" }}
            >
              Select Color :
            </label>
            <input
              type="color"
              value={formValues.color}
              name="color"
              onChange={handleChange}
              className="color-input"
            ></input>
            <button
              style={{
                borderRadius: "10px",
                marginLeft: "10px",
                border: "2px solid gray",
              }}
              onClick={resetColor}
            >
              Clear Selection
            </button>
            <p>Color selected : {formValues?.color}</p>
          </Grid>

          <Grid width={"50%"} style={{ marginLeft: "25px", marginTop: "5px" }}>
            <label
              style={{ padding: "5px", paddingLeft: "0px", display: "block" }}
            >
              Select Size :
            </label>

            <Select
              isMulti
              onChange={(data) => {
                handleMultiple("size", data);
              }}
              options={sizes}
              value={formValues.size}
            />
          </Grid>

          {/* size */}
          <Grid width={"95%"} style={{ display: "flex", marginLeft: "25px" }}>
          </Grid>
          <Grid item xs={12}>
            <button className={styles.button} onClick={submithandler}>
              Save Product
            </button>
          </Grid>
        </Grid>
      </div>

      <div className={styles.container}>
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                {/* <th>Variant Type</th> */}
                <th>Variant Data</th>
                <th>Mrp Price</th>
                <th>Selling Price</th>
                <th>Delivery Charges</th>
                <th>Estimated Delivery</th>
                <th>Quantity</th>
                <th>Res. Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {variantsData.map((variants, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <>
                        <p>Color: {variants?.color}</p>
                        <p>
                          Size:{" "}
                          {variants?.size?.map((x) => {
                            return <span>{x.value}, </span>;
                          })}
                        </p>
                      </>
                    </td>
                    <td>{variants.mrp_price}</td>
                    <td>{variants.selling_price}</td>
                    <td>{variants.delivery_charges}</td>
                    <td>{variants.estimated_delivery_in_days}</td>
                    <td>{variants?.variantInventory?.quantity}</td>
                    <td>{variants?.variantInventory?.reserve_qty}</td>
                    <td>
                      <MdDelete
                        onClick={() => delModelHandle(variants._id)}
                        className="product-delete"
                      />
                      {variants.default ? (
                        <p className="default-text def-badge">default</p>
                      ) : (
                        <p
                          className="default-text def-badge"
                          onClick={() => setvariantDefault(variants._id)}
                          style={{ cursor: "pointer" }}
                        >
                          set default
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deleteParent(x)}
      />

      <Grid item xs={12}>
        <Link to="/ecommerce/product" className={styles.button}>
          Product List
        </Link>
      </Grid>
    </>
  );
};

export default AddProductVariants;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Product Variant ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
