export const ecommerceList = [
    {
      id: 1,
      catname: "Ecommerce",
      subcategory: [
        {
          subcatname: "Order History",
          url: "orders"
        },
        {
          subcatname: "Category Management",
          url: "category"
        },
        {
          subcatname: "Product Management",
          url: "product"
        },
      ],
    }
  ];
  