import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import {
  getKidDataByParentId,
  getRegisteredParents,
} from "../../../services/User";
import styles from "./activity.module.css";
export default function ListOfKids() {
  const [kidsArr, setKidsArr] = useState([]);
  const [parentObj, setParentObj] = useState({});
  let id = useParams();

  const getkidsFromParentId = async () => {
    try {
      let { data: res } = await getKidDataByParentId(id.id);
      console.log(res.data);
      if (res.success == true) {
        setParentObj(res?.data);
        setKidsArr(res?.data.familyObj.kidIdArr);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        // Swal.fire(error?.message, "this is errore");
      }
    }
  };

  useEffect(() => {
    getkidsFromParentId();
    // getActivityByCategoryId();
  }, []);

  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Registered On</th>
          </tr>
        </thead>
        <tbody>
          {kidsArr &&
            kidsArr?.map((el, index) => {

                // console.log(el?.kidData?.dob, 'el?.kidData?.dob')
                let kidYear = el?.kidData?.dob?.split('/')[1];

                // console.log(kidYear, 'kid year')
                var now = new Date();
                let currentYear = now.getFullYear();
                var kidAge = currentYear - kidYear;

                // console.log(kidAge, 'kid age')

              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {el?.kidData?.firstName} {el?.kidData?.lastName}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {kidAge ? kidAge : '0'} Yrs
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {new Date(el?.kidData?.createdAt).toDateString()}
                      </p>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {kidsArr?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>
                  List of all Kids for {parentObj.firstName}{" "}
                  {parentObj.lastName}
                </h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <h3 style={{ padding: 0, width: "max-content" }}>
                  Total Kids : {kidsArr.length}
                </h3>
              </div>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
