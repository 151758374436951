import React from "react";
import { useNavigate } from "react-router";
import Nutrition from "../../../Assets/images/Nutrition.png";
import "../../../Assets/Styling/adminHome.css";
import styles from "./activity.module.css";

export default function Reports() {
  let navigate = useNavigate();
  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          <div style={{ paddingLeft: "4em" }} className="col playlistTitle p-0">
            <h3 style={{ paddingLeft: 80 }}>Reports </h3>
          </div>
          <div
            style={{ padding: "0px 4em " }}
            className="d-flex flex-wrap justify-content-between"
          >
            <div
              onClick={() => navigate(`/viewParents`)}
              className="catItem2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              {/* <img
                                width={111}
                                height={132}
                                className='catitemImg'
                                src={Nutrition}
                                alt='catItem'
                            /> */}
              <p className="catitemName">View All Parents</p>
            </div>
            <div
              className="catItem2"
              onClick={() => navigate(`/viewAllKids`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View All Kids</p>
            </div>
            <div
              className="catItem2"
              onClick={() => navigate(`/viewAllSubscription`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View All Subscriptions</p>
            </div>
            <div
              onClick={() => navigate(`/viewAllUsers`)}
              className="catItem2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              {/* <img
                                width={111}
                                height={132}
                                className='catitemImg'
                                src={Nutrition}
                                alt='catItem'
                            /> */}
              <p className="catitemName">All User Reports</p>
            </div>
            <div
              className="catItem2"
              onClick={() => navigate(`/Allwallet`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View All Wallet</p>
            </div>

            <div
              className="catItem2"
              onClick={() => navigate(`/DeletedUsers`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View Deleted Users</p>
            </div>
            <div
              className="catItem2"
              onClick={() => navigate(`/AllMissions`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View Missions</p>
            </div>
            <div
              className="catItem2"
              onClick={() => navigate(`/AllVideos`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View Videos</p>
            </div>

            <div
              className="catItem2"
              onClick={() => navigate(`/viewDeviceDetails`)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
                cursor: "pointer",
              }}
            >
              <p className="catitemName">View Registered Devices</p>
            </div>

            {/* <div className='catItem2' onClick={() => navigate(`/`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Wallet Report</p>
                        </div>
                        <div className='catItem2' onClick={() => navigate(`/viewAllSubscription`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Payment Report</p>
                        </div>
                        <div className='catItem2' onClick={() => navigate(`/viewAllSubscription`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Order Report</p>
                        </div>
                        <div className='catItem2' onClick={() => navigate(`/viewAllSubscription`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Product Activity Report</p>
                        </div>
                        <div className='catItem2' onClick={() => navigate(`/viewAllSubscription`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Mission Report</p>
                        </div>
                        <div className='catItem2' onClick={() => navigate(`/viewAllSubscription`)} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, cursor: "pointer" }}>
                            <p className='catitemName'>Video Report</p>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
