import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { getRegisteredParents } from "../../../services/User";
import { addBonusAmount, getAllWallet } from "../../../services/Wallet";
import styles from "./activity.module.css";
import Walletexport from "./walletexport";
import NewLoader from "../../../Component/NewLoader";
import Backdrop from "../../../Component/BackLoader";

export default function AllWallet() {
  const [parentsArr, setParentsArr] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const [addAmountShow, setAddAmountShow] = useState(false);
  const [showExportModal, setExportModal] = useState(false);
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();
  let id = useParams();
  const handleShowExportMod = () => {
    setExportModal(!showExportModal);
  };

  const getWallet = async () => {
    try {
      let { data: res } = await getAllWallet();
      console.log(res.data);
      if (res.success == true) {
        setParentsArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        // Swal.fire(error?.message, "this is errore");
      }
    }
  };

  const showAddModel = (id) => {
    console.log(id);
    if (id && activeUser === "") {
      setActiveUser(id);
    } else {
      setActiveUser("");
    }
    setAddAmountShow(!addAmountShow);
  };

  useEffect(() => {
    getWallet();
    // getActivityByCategoryId();
  }, []);

  const TableListElement = ({ modelHandle }) => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>User Id</th>
            <th>Name</th>
            <th>Phone</th>
            <th>amount</th>
            <th>Add Bonus Amount</th>

            {/* <th>Registered On</th> */}
            {/* <th>No. of Kids</th> */}
            {/* <th>View Kids</th> */}
          </tr>
        </thead>
        <tbody>
          {parentsArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {el?._id}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {el?.userId?.firstName} {el?.userId?.lastName}
                  </p>
                </div>
              </td>

              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {el?.userId?.phone}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {el?.amount}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  {!el?.userId?.isBonusAdded && el?.userId?.role !== "KID" ? (
                    <button
                      className="addPlaylistBtn primaryBtn"
                      onClick={() => modelHandle(el?.userId._id)}
                    >
                      Add Bonus Amount
                    </button>
                  ) : el?.userId?.role !== "KID" ? (
                    <p
                      className="tableInputTxt"
                      style={{ marginLeft: "-1em" }}
                      type="text"
                      name="column_1"
                    >
                      Already Added Bonus Amount
                    </p>
                  ) : (
                    <p
                      className="tableInputTxt"
                      style={{ marginLeft: "-1em" }}
                      type="text"
                      name="column_1"
                    >
                      Not Eligible For Bonus
                    </p>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {loader && <Backdrop />}
    <div className="home-outer">
      <Walletexport show={showExportModal} handleClose={handleShowExportMod} />

      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {parentsArr?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>All User Wallet</h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <h3 style={{ padding: 0, width: "max-content" }}>
                  Total Users : {parentsArr.length}
                </h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <button
                  className="addPlaylistBtn primaryBtn"
                  onClick={() => handleShowExportMod()}
                >
                  Export Report
                </button>
              </div>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement modelHandle={(x) => showAddModel(x)} />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      {activeUser ? (
        <UpdateWalletModel
          getData={() => getWallet()}
          activeUser={activeUser}
          show={addAmountShow}
          handleClose={() => showAddModel()}
          setLoader={setLoader}
        />
      ) : (
        ""
      )}
    </div>
    </>
  );
}

const UpdateWalletModel = ({ getData, activeUser, show, handleClose, setLoader }) => {
  const [data, setData] = useState({
    userId: activeUser,
    message: "",
    amount: 0,
  });


  const inputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handelSubmit = async () => {
    try {
      console.log("Handle Submit Hit");
      if (data.message == "") {
        Swal.fire("Transaction Message can not be empty");
        return;
      } else if (data.amount == 0) {
        Swal.fire("Amount to be added not equal to 0");
        return;
      } else if (data.amount > 3000) {
        Swal.fire("Amount to be added not more then 3000");
        return;
      } else {
        console.log("Route Hit");
        handleClose();
        setLoader(true);
        const res = await addBonusAmount(data);
        // console.log(res);
        if (res.status === 200) {
          // console.log(res.data);
        setLoader(false);

        Swal.fire("Transaction Complete!");
          await getData();
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire(error?.response?.data?.message);
      return;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="modelMain">
          <Modal.Body style={{}} className="modelBody">
            <div className="row container m-2">
              <label
                className="primaryText"
                style={{ marginBottom: 20, fontSize: "25px" }}
              >
                Add Amount to User Wallet
              </label>
              <div className="col-12 catName m-0 ">
                <label>Message to Add Transaction </label>
                <input
                  className="catInput"
                  required
                  name="message"
                  placeholder="Add Transaction Message to Wallet"
                  type="text"
                  onChange={inputChange}
                ></input>
              </div>
              <div className="col-12 catName m-0 ">
                <label>Amount to Add</label>
                <input
                  className="catInput"
                  required
                  name="amount"
                  placeholder="Add Amount Maximum Amount is 3000 to Add"
                  type="number"
                  min={1}
                  max={4}
                  onChange={inputChange}
                ></input>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn"
                variant="primary"
                onClick={() => handelSubmit()}
              >
                Add Bonus
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
