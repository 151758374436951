import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import { getAllParentsByDate } from "../../../services/reports";
import Papa from "papaparse";

const ReportsExportsModels = ({show, handleClose, handleSubmit}) => {
    const [selectFrom, setSelectFrom] = useState('');
    const [selectTo, setSelectTo] = useState('');
    const [exportList, setExportList] = useState([])
    const [loading, setLoading] = useState(false);
    // const [headings, setHeadings] = useState([
    //   {label:"Parent ID", key: "parentID"},
    //   {label:"Parent Name", key: "parentName"}, 
    //   {label:"Phone Number", key: "phoneNumber"}, 
    //   {label:"Email Address", key: "emailAddress"},
    //   {label:"Kid ID", key: "kidId"},
    //   {label:"Kid Name", key: "kidName"},
    //   {label:"Kid Age", key: "kidAge"},
    //   {label:"Sign up date & time", key: "signUpDateAndTime"},
    //   {label:"Subscription Plan Validity Till", key: "subscriptionPlanValidityTill"},
    //   {label:"Subscription Plan Purchased Date & Time", key: "subscriptionPlanPurchasedOn"},
    //   {label:"Coupon Applied", key: "couponApplied"},
    //   {label:"Referral Code Applied", key: ""},
    //   {label:"Referral Code of Parent", key: "referralCodeOfAboveParentId"},
    //   {label:"Referral Parent ID", key: "referralParentID"},
    //   {label:"Parent Insert Date Time", key: "parentInsertDateAndTime"},
    //   {label:"Parent Last Update Date Time", key: "parentLastUpdateDateAndTime"},
    //   {label:"Kid Insert date Time", key: "kidInsertDateAndTime"},
    //   {label:"Kid Update Date Time", key: "kidUpdateDateAndTime"},
    //   {label:"Wallet Id", key: "favcyWalletId"}
    // ])
    const [headings, setHeadings] = useState(["Parent ID", "Parent Name", "Phone Number", "Email Address", "Kid ID", "Kid Name", "Kid Age", "User up date & time", "Subscription Plan Paid Amount", "Subscription Plan Start Date", "Subscription Plan Validity Till", "Coupon Applied", "User Referral Code", "Referral Parent ID", "Parent Creation Date Time", "Parent Last Updated", "Kid Creation on", "Kid Last Updated", "Parent Wallet Id", ])

  const exportReport = async (x) => {
      const res = await getAllParentsByDate(x);
      if(res.status === 200){
          setExportList(res.data.data)
          let dataDate = {
            filename: `all User Report ${new Date()}.csv`,
            columns: headings,
            data: res.data.data
          }
          await downloadCSV(dataDate);
          handleClose();
      }
  }

  const downloadCSV = async (args) => {  

    let filename = args.filename || 'export.csv';
    let columns = args.columns || null;
  
    let csv = Papa.unparse({ data: args.data, fields: columns})
    if (csv == null) return;
  
    var blob = new Blob([csv]);
    if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, args.filename);
    else
    {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
        a.download = filename;
        document.body.appendChild(a);
        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
    }
  
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
      >
        <div className="modelMain">
          <Modal.Body style={{}} className="modelBody">
            <div className="row container m-2">
              <label
                className="primaryText"
                style={{ marginBottom: 20, fontSize: "25px" }}
              >
                Export Report in CSV
              </label>
              <div className="col-12 catName m-0 ">
                <label>Select From: </label>
                <input
                  className="catInput"
                  required
                  type="date"
                  value={selectFrom}
                //   step="3"
                  max={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  }
                  onChange={(e) => setSelectFrom(e.target.value)}
                ></input>
              </div>
              <div className="col-12 catName m-0 ">
                <label>Select To: </label>
                <input
                  className="catInput"
                  required
                  type="date"
                  value={selectTo}
                //   step="0"
                  max={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate()
                    )
                  }
                  onChange={(e) => setSelectTo(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                    handleClose();
                }}
              >
                Cancel
              </button>
                <button
                  className="mainButton modelBtn"
                  variant="primary"
                  onClick={() => exportReport({selectFrom, selectTo})}
                >
                Export Report
                </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ReportsExportsModels;
