// for production
const API = {
  BASE_LINK: "https://api.goodgoodpiggy.com/api",
  Ecommerce: "https://api.goodgoodpiggy.com/api",
  PAYMENT_URL: "https://admin.goodgoodpiggy.com/",
};

// dev
// const API = {
//   BASE_LINK: "https://devapi.goodgoodpiggy.in/api",
//   Ecommerce: "https://devapi.goodgoodpiggy.in/api",
//   PAYMENT_URL: "https://devadmin.goodgoodpiggy.in/",
// };

/* for Local Machine */
// const API = {
//  BASE_LINK: "http://192.168.1.11:4012/api",
//  Ecommerce:"http://192.168.1.11:4012/api",
//  PAYMENT_URL:"http://192.168.1.11:3000",
// }

export default API;
