import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Nav, Tab } from "react-bootstrap";
import ButtonMain from "../../Component/Button";
import NavbarComponent from "../../Component/Navbar";
import "../../Assets/Styling/adminHome.css";
import tab1 from "../../Assets/images/categories.png";
import tab2 from "../../Assets/images/activity.png";
import tab3 from "../../Assets/images/playlist.png";
import tab4 from "../../Assets/images/report.png";
import uploadIcon from "../../Assets/images/uploadicon.png";
import Nutrition from "../../Assets/images/Nutrition.png";
import menu from "../../Assets/images/Menu.png";
import deleteImg from "../../Assets/images/delete.png";
import pencil from "../../Assets/images/pencil.png";
import editImg from "../../Assets/images/edit.png";
import EllipseImg from "../../Assets/images/Ellipse.png";
import GroupImg from "../../Assets/images/Group.png";
import pigImg from "../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import SidenavTabs from "../../Component/SidenavTabs";
import $ from 'jquery'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { deleteSingleCategory, categoryAdd, getAllCategoryList, getAllactivityList, getSingleCategory } from "../../redux/actions/admin.actions";
import AddActivity from "./activity/AddActivity/AddActivity";
import { addNewCategory, getAllCategories } from "../../services/category";
import Swal from 'sweetalert2'

function Home() {
  const [showCatAddModel, setShowCatAddModel] = useState(false);
  const [showPlaylistModel, setShowPlaylistModel] = useState(false);
  const [fromCount, setFromCount] = useState(18);
  const [toCount, setToCount] = useState(18);
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [showReporting, setShowReporting] = useState(false);
  const [showItemOption, setShowItemOption] = useState(false);
  const [showDeletePlaylistModel, setShowDeletePlaylistModel] = useState(false);
  const [activeTab, setActiveTab] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowItemId, setRowItemId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [categoryItems, setCategoryItems] = useState([])
  const [selectedCatFile, setSelectedCatFile] = useState("");
  //
  const [catList, setCatList] = useState([]);
  const [catName, setCatName] = useState("");
  const [catImg, setCatImg] = useState("");
  const [catAgeGroup, setCatAgeGroup] = useState({})
  const [catItemId, setCatItemId] = useState("")
  const [showDeleteCatModel, setShowDeleteCatModel] = useState(false);
  const [resetForm, setResetForm] = useState(true);

  let navigate = useNavigate()
  const dispatch = useDispatch();
  //redux state
  const { categoryList, activityList, singleCatInfo } = useSelector((state) => state);
  // const { catList, loading } = categoryList
  const { singleCatState, } = singleCatInfo

  const addCatHandle = () => {
    setShowCatAddModel(true);
  };
  const handleClose = () => {
    setCatName("");
    setToCount("")
    setFromCount("")
    setResetForm(true);
    setShowCatAddModel(false);
  }
  const handleItemHover = (i) => {
    setShowItemOption(true);
  }
  const handleItemHoverLeave = () => {
    setShowItemOption(false);
  }

  const addPlaylistHandle = () => {
    setShowPlaylistModel(true)
  }

  ///table handles

  const handleTableChange = item => e => {
    const { name, value } = e.target;
    // const rows = [...rows];
    // rows[index] = {
    //     [name]: value
    // };
    let items = rows.map(row => {
      if (row.id === item.id) {
        row[name] = value;
      }
      return row;
    });
    setRows(items);
  };
  const handleRemoveSpecificRow = item => () => {
    // const rows = [...rows];
    // rows.splice(index, 1);
    // setRows(rows);
    if (rowItemId !== null) {

      let items = rows.filter(row => row.id != rowItemId);
      setRows(items);
    }
  };
  const handleAddRow = () => {
    let item = {
      id: rows.length + 1,
      column_1: "",
      column_2: ""
    };
    setRows([...rows, item]);
  };
  const rowItemIdHandle = (e) => {
    console.log(`item.id`, e.target.checked)
    setRowItemId(e.target.id)
  }
  const editRowHandle = (e) => {
    setChecked(true)
  }
  const editCategoryItem = (id) => {
    // console.log(`id`, id)
    setCatItemId(id);
    // dispatch(getSingleCategory(id))
    setShowCatAddModel(true);
    // setResetForm(false)
  }
  const deleteCatItemHandle = (id) => {
    // console.log('dlt clicked');
    setShowDeleteCatModel(true)
    setCatItemId(id);
  }

  const deleteCatItemApi = () => {
    console.log(`catItemId>>`, catItemId)
    // dispatch(deleteSingleCategory(catItemId))
    setShowDeleteCatModel(false)

  }

  const AddActivityHandle = () => {
    navigate('/home/activity')
  }

  //add category api
  const addCatSubmitHandler = async () => {
    try {
      let obj = {
        startAge: fromCount, endAge: toCount
      }
      const formData = new FormData
      formData.append('file', selectedCatFile)
      formData.append('name', catName)
      formData.append('ageGroupObj', JSON.stringify(obj))
      const res = await addNewCategory(formData);
      if (res.success == true) {
        Swal.fire(res.message)
        setShowCatAddModel(false);
        getCategoryApi()
      }
      console.log(res, "Asdasdasdasdasadasdaadd")
    }
    // dispatch(categoryAdd(catName, selectedCatFile, fromCount, toCount));
    // dispatch(getAllCategoryList());
    catch (err) {
      console.log(err)
    }
  };

  const getCategoryApi = async () => {
    try {

      const res = await getAllCategories()
      // console.log(`data tesstas `, data)
      setCatList(res)
    }
    catch (error) {
      console.error(error)
    }
  }


  //onpage load 
  useEffect(() => {

    getCategoryApi()
  }, [])



  const CatItem = (props, key) => {
    const { list, catItemOnclick, activeTab, itemHover, itemHoverLeave, deleteCatItem, obj, ...rest } = props
    const [itemOptions, setItemOptions] = useState(false)

    return (
      <div onMouseEnter={() => setItemOptions(true)} onMouseLeave={() => setItemOptions(false)} className="catItem">
        {/* {itemOptions && ( */}

        <div className="itemOptions">
          <div style={{ cursor: "pointer", }} className="" onClick={() => editCategoryItem(list._id)}>
            <img className="itemOptionIcon" src={pencil} alt="pencil" />
            <label className="editOption" htmlFor="">Edit</label>
          </div>
          <div style={{ cursor: "pointer", }} className="" onClick={() => deleteCatItem(list._id)}>
            <img className="itemOptionIcon" src={deleteImg} alt="delete" />
            <label className="editOption" htmlFor="">Delete</label>
          </div>
        </div>
        {/* )} */}
        <div className="menu"></div>
        {/* //add no image condition  */}
        {list.thumbnailImage === undefined ? (
          <img width={111} height={132} className="catitemImg" src={Nutrition} alt="catItem" />
        ) : (
          <img width={111} height={132} className="catitemImg" src={list.thumbnailImage} alt="catItem" />
        )}
        <p className="catitemName">{list.name}</p>
      </div>

    )
  }
  const ActItem = (props, key) => {
    const { list, itemOptions, activeTab, itemHover, itemHoverLeave, editItem } = props
    const goto = (id) => {
      localStorage.setItem('itemId', id)
      navigate('/home/activity')
    }


    return (
      <div className="actItem" onClick={() => goto(list._id)}>
        {/* //todo: send activity id */}
        {itemOptions && (
          <div className="itemOptions">
            <div style={{ display: 'block', cursor: "pointer" }} className="">
              <img className="editOption" src={pencil} alt="pencil" />
              <label className="editOption" htmlFor="">Edit</label>
            </div>
            <img src={deleteImg} alt="delete" />
            <label className="editOption" htmlFor="">Delete</label>
          </div>
        )}
        <div className="menu"></div>
        {list.thumbnailImage === undefined ? (
          <img width={111} height={132} className="actitemImg" src={Nutrition} alt="catItem" />
        ) : (
          <img width={111} height={132} className="actitemImg" src={list.thumbnailImage} alt="catItem" />
        )}
        <p className="catitemName">{list.name}</p>
      </div>

    )
  }



  return (
    <div className="adminHome">

      <NavbarComponent search={true} />
      <div className="home-outer">
        {/* <SidenavTabs activeTab="first" /> */}
        <div className="container mainContent tab-content" id="v-pills-tabContent" >
          <div className={categoryList.loading === true ? "row tab-pane fade" : "row tab-pane fade show active"} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <div className="category-outer ">

              {loading === true ? (
                <div className="category">
                  <button onClick={addCatHandle} className="categoryBtn ">
                    Add Category
                  </button>
                </div>
              ) : (
                <div className="row">
                  <div className="">
                    <button style={{ float: "right", margin: "2em 1em 0 1em" }} onClick={addCatHandle} className="categoryBtn ">
                      Add Category
                    </button>
                  </div>
                  <div style={{ paddingLeft: "5em" }} className="d-flex flex-wrap">
                    {/* asda */}
                    {catList.data?.map((catList, key) => (
                      <CatItem deleteCatItem={deleteCatItemHandle} editItem={editCategoryItem} key={key} list={catList} activeTab={() => setActiveTab(true)} itemOptions={showItemOption} itemHover={handleItemHover} itemHoverLeave={handleItemHoverLeave} />
                    ))}
                  </div>
                </div>
              )}

              <Modal className="modelmain-outer" show={showDeleteCatModel} onHide={() => setShowDeleteCatModel(false)}>
                <div className="modelMain">

                  <Modal.Body className="modelBody ">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col d-flex ">
                          <h3 className="deletePlaylistTitle">Are you sure you want to delete the category?</h3>
                        </div>
                        <div className="col modelFooter mt-4">
                          <button
                            style={{ width: "160px" }}
                            id="deleteRow"
                            className="mainButton modelBtn cancelBtn"
                            variant="secondary"
                            onClick={deleteCatItemApi}
                          >
                            Yes, remove it
                          </button>
                          <button

                            className="mainButton modelBtn saveBtn"
                            style={{
                              width: "160px"
                            }}
                            variant="primary"
                            onClick={() => setShowDeleteCatModel(false)}
                          >
                            No, i just clicked it
                          </button>
                        </div>
                      </div>
                      <img width={124} src={pigImg} alt="" />
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer></Modal.Footer> */}
                </div>
              </Modal>

              <Modal show={showCatAddModel} onHide={handleClose}>
                <div className="modelMain">
                  {/* <Modal.Header closeButton>
                              <Modal.Title></Modal.Title>
                           </Modal.Header> */}
                  <Modal.Body className="modelBody">
                    <div className="container m-2">

                      <div className="catName m-0">
                        <label>Category Name:</label>
                        <input
                          className="catInput"
                          placeholder="Type Category Name Here"
                          required
                          value={catName}
                          onChange={(e) => setCatName(e.target.value)}
                        ></input>
                      </div>
                      <div className="uploadImg">
                        <label>Upload Image</label>
                        <div className="upload-outer">
                          {resetForm ? (
                            <>
                              <input
                                className="catInput inputFile"
                                type="file"
                                // value={singleCatInfo.loading === false || singleCatState.thumbnailImage !== undefined ? singleCatState.data.thumbnailImage : null}
                                onChange={(e) => setSelectedCatFile(e.target.files[0])}
                              ></input>

                              <img
                                className="uploadIcon"
                                src={uploadIcon}
                                alt="upload"
                              />
                            </>
                          ) : singleCatInfo.loading === false ? (
                            <div style={{ position: "relative" }} className="">
                              <img style={{ display: "block", margin: "auto" }} width={100} src={singleCatState.data.thumbnailImage} alt="catImg" />
                              <input className="customInput my-auto" type="file" name="" id="" />
                              <img style={{ top: "25%", left: "43%" }} className="uploadIcon" src={uploadIcon} alt="" />
                            </div>
                          ) : null}

                        </div>
                      </div>
                      <div className="ageGroup">
                        <label>Age Group: </label>
                        <div className="ageGroup-inner">
                          <p>From</p>
                          <div className="agegroupBtn-outer">
                            <button
                              className="agegroupBtn"
                              onClick={() => setFromCount(parseInt(fromCount) + 1)}
                            >
                              +
                            </button>
                            <p>{resetForm ? fromCount : singleCatInfo.loading === false ? singleCatState.data.ageGroupObj.startAge : null}</p>
                            <button
                              style={{ marginRight: '-2px' }}
                              className="agegroupBtn "
                              onClick={() => setFromCount(parseInt(fromCount) - 1)}
                            >
                              -
                            </button>
                          </div>
                          <p>To</p>
                          <div className="agegroupBtn-outer">
                            <button
                              className="agegroupBtn"
                              onClick={() => setToCount(parseInt(toCount) + 1)}
                            >
                              +
                            </button>
                            <p>{resetForm ? toCount : singleCatInfo.loading === false ? singleCatState.data.ageGroupObj.endAge : null}</p>
                            <button
                              style={{ marginRight: '-2px' }}
                              className="agegroupBtn"
                              onClick={() => setToCount(parseInt(toCount) - 1)}
                            >
                              -
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelFooter justify-content-center">
                      <button
                        className="mainButton modelBtn"
                        variant="secondary"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="mainButton modelBtn"
                        variant="primary"
                        onClick={addCatSubmitHandler}
                      >
                        Save
                      </button>
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer></Modal.Footer> */}
                </div>
              </Modal>
            </div>
          </div>

          <div className="row tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <div className="col">
              {/* <button style={{ float: 'right', marginTop: '2em' }} onClick={addCatHandle} className="categoryBtn">
                Add Category
              </button> */}
            </div>
            <div className="activity-outer justify-content-start">
              {loading === true ? (
                <button onClick={AddActivityHandle} className="categoryBtn ">
                  Add Activity
                </button>
              ) : (

                <div className="row">
                  <div style={{ paddingLeft: "5em" }} className="d-flex flex-wrap">
                    {catList.data?.map((catList, key) => (
                      <ActItem key={key} list={catList} activeTab={() => setActiveTab(true)} itemOptions={showItemOption} itemHover={handleItemHover} itemHoverLeave={handleItemHoverLeave} />
                    ))}
                  </div>

                </div>

              )}

              {/* <CatItem activeTab={() => setActiveTab(true)} itemOptions={showItemOption} itemHover={handleItemHover} itemHoverLeave={handleItemHoverLeave} />
              <CatItem itemOptions={showItemOption} /> */}

            </div>
          </div>

          <div className="container playlist-outer tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
            <div className="row ">
              {/* <h2>Category Name</h2> */}
              <div className="col-md activityList">
                <div className="row">
                  <div className="col playlistTitle">
                    <h3>List of All Playlist:</h3>
                  </div>
                  <div className="col-lg-3">
                    <div className="icon-outer">
                      <img className="tableIcon" onClick={handleAddRow} src={EllipseImg} alt="" />
                      <img className="tableIcon" src={editImg} onClick={editRowHandle} alt="" />
                      <img onClick={handleRemoveSpecificRow()} className="tableIcon" src={GroupImg} alt="delete" />
                    </div>
                  </div>
                  <Modal className="modelmain-outer" show={showDeletePlaylistModel} onHide={() => setShowPlaylistModel(false)}>
                    <div className="modelMain">

                      <Modal.Body className="modelBody ">
                        <div className="d-flex">
                          <div className="row">
                            <div className="col d-flex ">
                              <h3 className="deletePlaylistTitle">Are you sure you want to delete this video?</h3>
                            </div>
                            <div className="col modelFooter mt-4">
                              <button
                                style={{ width: "160px" }}
                                id="deleteRow"
                                className="mainButton modelBtn cancelBtn"
                                variant="secondary"
                                onClick={handleRemoveSpecificRow}
                              >
                                Yes, remove it
                              </button>
                              <button

                                className="mainButton modelBtn saveBtn"
                                style={{
                                  width: "160px"
                                }}
                                variant="primary"
                                onClick={() => setShowDeletePlaylistModel(false)}
                              >
                                No, i just clicked it
                              </button>
                            </div>
                          </div>
                          <img width={124} src={pigImg} alt="" />
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer></Modal.Footer> */}
                    </div>
                  </Modal>
                </div>
                <div className="playlistTable">
                  {/* <div className="row table-inner">
                    <div className="col tableTitle">
                      <p>Playlist Name</p>
                    </div>
                    <div className="col tableTitle">
                      <p>Age Group</p>
                    </div>
                  </div> */}
                  <div className="row tableMain">
                    {/* <ul style={{ listStyleType: "none" }}> */}
                    {/* <TableLiElement checked={checked} rows={rows} white={true} handleTableChange={handleTableChange} rowItemId={rowItemIdHandle} editRow={editRowHandle} /> */}
                    {/* <button style={{ width: "10%" }} id="deleteRow"> del</button> */}
                    {/* <TableLiElement /> */}
                    {/* <TableLiElement white={true} /> */}
                    {/* </ul> */}
                  </div>
                </div>
                <Link to='/home/add-playlist'>
                  <button onClick={addPlaylistHandle} className="addPlaylistBtn primaryBtn">Add playlist</button>
                </Link>
              </div>

            </div>
          </div>
          <div className="reporting tab-pane fade" id="v-pills-reportings" role="tabpanel" aria-labelledby="v-pills-reporting-tab">

          </div>
          {catList.length == 0 && (

            <div className="defaultTab tab-pane fade show active">
              <p className="primaryText">Please add Categories first</p>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}


export const TableLiElement = (props) => {
  const { white, rows, handleTableChange, rowItemId, editRow, checked } = props
  return (
    // <li className={white ? "whiteLi" : "darkLi"}>
    //   <div className="row">
    //     <div className="col-1 my-auto">
    //       <input style={{ marginLeft: "2em" }} type="checkbox" />
    //     </div>
    //     <div className="col my-auto">
    //       <p>name</p>
    //     </div>
    //     <div className="col my-auto">
    //       <p>age group</p>
    //     </div>
    //   </div>
    // </li>
    <>
      {/* {
        rows.length !== 0 ? (
          <table id="resultTable">
            <tr id="first" className="table-title">
              <th>Playlist Name</th>
              <th>Age Group</th>
            </tr>
            {rows.map((item, index) => (
              <>
                <tr id="second" key={index}>
                  <td>
                    <input onChange={rowItemId} type="radio" name="pn" id={item.id} />
                    <textarea autoFocus className="tableInputTxt" type="text" name="column_1" value={item.column_1} onChange={handleTableChange(item)}></textarea>
                  </td>
                  <td>
                    <input className="tableInputTxt" type="text" name="column_2" value={item.column_2} onChange={handleTableChange(item)} />
                  </td>
                </tr>
              </>
            ))}
          </table>
        ) : null
      } */}

      <table id="resultTable">
        <tr id="first" className="table-title">
          <th>Playlist Name</th>
          <th>Age Group</th>
        </tr>
        {/* {rows.map((item, index) => ( */}
        <>
          <tr id="second" key={'1'}>
            <td>
              <input onChange={rowItemId} type="radio" name="pn" id={'2'} />
              <textarea className="tableInputTxt" type="text" name="column_1" value={'item.column_1'} onChange={handleTableChange}></textarea>
            </td>
            <td>
              <input className="tableInputTxt" type="text" name="column_2" value={'item.column_2'} onChange={handleTableChange} />
            </td>
          </tr>
        </>
        {/* ))} */}
      </table>

    </>
  )
}





export default Home;
