// 
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { getallKids, getRegisteredParents } from '../../../services/User'
import styles from './activity.module.css'
export default function ListOfAllKids() {
    const [kidsArr, setKidssArr] = useState([])
    let navigate = useNavigate()

    const getAllKidsData = async () => {
        try {
            let { data: res } = await getallKids()
            console.log(res.data)
            if (res.success == true) {
                setKidssArr(res?.data)

            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message)
            } else {
                // Swal.fire(error?.message, "this is errore");
            }
        }
    }




    useEffect(() => {
        getAllKidsData()
        // getActivityByCategoryId();
    }, [])


    const TableListElement = () => {
        return (
            <table id='resultTable'>
                <thead id='first' className='table-title'>
                    <tr>
                        <th>Name</th>
                        <th>Parent Name</th>
                        <th>Registered On</th>
                    </tr>
                </thead>
                <tbody>
                    {kidsArr.map((el, index) => (
                        <tr key={index}>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.firstName} {el?.lastName}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.parentObj?.firstName ? el?.parentObj?.firstName : "NA"} {el?.parentObj?.lastName}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {new Date(el?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <div className='home-outer'>
            <div
                className={'container col playlist-outer pt-5 ' + styles.activityInnner}
            >
                <div className='row'>
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: '8rem' }} className='col-md'>
                        <div className='row'>
                            <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                                {kidsArr?.name}
                            </h1>
                            <div className='col playlistTitle p-0'>
                                <h3 style={{ padding: 0 }}>List of all Kids</h3>
                            </div>
                            <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex" }}>
                                <h3 style={{ padding: 0, width: "max-content" }}>Total Kids : {kidsArr?.length}</h3>
                            </div>


                        </div>
                        <div className='playlistTable'>
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className='row tableMain'>
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

