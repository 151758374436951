import React, { useEffect, useState } from "react";
import NavbarComponent from "../../../../Component/Navbar";
import SidenavTabs from "../../../../Component/SidenavTabs";
import uploadIcon from "../../../../Assets/images/uploadicon.png";
import { TableLiElement } from "../../Home";
import editImg from "../../../../Assets/images/edit.png";
import EllipseImg from "../../../../Assets/images/Ellipse.png";
import GroupImg from "../../../../Assets/images/Group.png";
import pigImg from "../../../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  activityAdd,
  deleteAttributeById,
  getAttributesByActId,
} from "../../../../redux/actions/admin.actions";

function AddActivity() {
  //formdata
  const [fromCount, setFromCount] = useState(18);
  const [toCount, setToCount] = useState(18);
  const [showDeletePlaylistModel, setShowDeletePlaylistModel] = useState(false);
  const [showPlaylistModel, setShowPlaylistModel] = useState(false);
  const [show, setShow] = useState(false);
  const [itemId, setItemId] = useState(localStorage.getItem("itemId"));
  const [actId, setActId] = useState(localStorage.getItem("actId"));
  const [actName, setActName] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { getAttributesById } = useSelector((state) => state);
  const { attributesState, loading } = getAttributesById;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    navigate("/home/activity");
  };
  const handleNavigation = () => {
    navigate("/home");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(activityAdd(actName, itemId, selectedFile, fromCount, toCount));
    navigate("/home/activity");
  };

  const handleDeleteAttribute = () => {
    const attId = localStorage.getItem("attId");
    dispatch(deleteAttributeById(attId));
    setShowDeletePlaylistModel(false);
    dispatch(getAttributesByActId(actId));
  };
  const selectAttribute = (id) => {
    console.log("clicked");
    localStorage.setItem("attId", id);
  };

  useEffect(() => {
    const actid = localStorage.getItem("actId");
    dispatch(getAttributesByActId(actid));
  }, [dispatch]);

  useEffect(() => {
    if (fromCount > toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else if (fromCount < 0) {
      setErrorMessage("cannot be in negative");
    }
    else if (fromCount > 17) {
      setErrorMessage("cannot be in greater than 17");
    }
    else if (toCount > 17) {
      setErrorMessage("cannot be in greater than 17");
    }
  }, [fromCount, toCount]);

  const TableLiElement = (props) => {
    const { handleTableChange, rowItemId, data } = props;
    return (
      <table id="resultTable">
        <tr id="first" className="table-title">
          <th>Attribute Name</th>
          <th>Age Group</th>
        </tr>
        {attributesState.data.map((data) => (
          <>
            <tr id="second" key={data._id}>
              <td>
                <div className="d-flex">
                  <input
                    onClick={() => selectAttribute(data._id)}
                    type="radio"
                    name="pn"
                    id="act"
                  />
                  {/* <input  type="radio" name="pn" id="act" /> */}
                  <p
                    className="tableInputTxt"
                    type="text"
                    name="column_1"
                    onChange={handleTableChange}
                  >
                    {data.name}
                  </p>
                </div>
              </td>
              <td>
                {/* <p className="tableInputTxt" type="text" name="column_2" onChange={handleTableChange}></p> */}
                <p
                  className="tableInputTxt"
                  type="text"
                  name="column_2"
                  onChange={handleTableChange}
                >
                  {data.ageGroupObj.startAge}-{data.ageGroupObj.endAge}
                </p>
              </td>
            </tr>
          </>
        ))}
      </table>
    );
  };

  return (
    <div>
      <NavbarComponent search={true} />
      <div className="home-outer">
        <SidenavTabs showHome={handleNavigation} activeTab={"second"} />
        <div className="innerPageContent">
          <div className="modelMain">
            <div className="container modelBody">
              <div className=" m-2 row flex-row">
                <div className="catName d-flex flex-row w-75 m-0">
                  <label className="my-auto p-2">Activity Name:</label>
                  <input
                    className="catInput w-50"
                    placeholder="Type Activity Name Here"
                    value={actName}
                    onChange={(e) => setActName(e.target.value)}
                  ></input>
                </div>

                <div className="row container-fluid">
                  <div className="col-4 uploadImg">
                    <label className="p-2">Upload Activity Image:</label>
                    <div style={{ marginLeft: 0 }} className="upload-outer p-1">
                      <input
                        className="catInput inputFile"
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      ></input>
                      {/* <div className=""> */}

                      <img
                        style={{
                          position: "absolute",
                          left: "46%",
                          top: "40%",
                        }}
                        // className="uploadIcon "
                        src={uploadIcon}
                        alt="upload"
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col ">
                    <div className="ageGroup p-2">
                      <label>Age Group: </label>
                    </div>
                    <div className="ageGroup flex-column ">
                      {/* <label>Age Group: </label> */}
                      <div className="ageGroup-inner pt-4">
                        <p>From</p>
                        <div className="agegroupBtn-outer">
                          <button
                            className="agegroupBtn"
                            onClick={() => setFromCount(previousState => parseInt(previousState) + 1)}
                          >
                            +
                          </button>
                          <input
                            className="ageCountInput"
                            value={fromCount}
                            type="number"
                            maxLength={10}
                            onChange={(e) => setFromCount(e.target.value)}
                          />
                          <button
                            style={{ marginRight: "-2px" }}
                            className="agegroupBtn"
                            onClick={() => setFromCount(previousState => parseInt(previousState) - 1)}
                          >
                            -
                          </button>
                        </div>
                        <p>To</p>
                        <div className="agegroupBtn-outer">
                          <button
                            className="agegroupBtn my-auto"
                            onClick={() => setToCount(previousState => parseInt(previousState) + 1)}
                          >
                            +
                          </button>
                          <input
                            className="ageCountInput"
                            value={toCount}
                            type="number"
                            maxLength={10}
                            onChange={(e) => setToCount(e.target.value)}
                          />
                          <button
                            style={{ marginRight: "-2px" }}
                            className="agegroupBtn"
                            onClick={() => setToCount(previousState => parseInt(previousState) - 1)}
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Modal.Footer></Modal.Footer> */}
          </div>
          <div
            style={{ width: "100%" }}
            className="container-fluid row mx-auto"
          >
            {/* <h2>Category Name</h2> */}
            <div className="col-md">
              <div className="row">
                <div className="row container ">
                  <div className="col playlistTitle ">
                    <h3>Attribute Name</h3>
                  </div>
                  <div className="col-lg-3">
                    <div className="icon-outer">
                      {/* <img className="tableIcon" src={EllipseImg} alt="" /> */}
                      <img className="tableIcon" src={editImg} alt="" />
                      <img
                        onClick={() => setShowDeletePlaylistModel(true)}
                        className="tableIcon"
                        src={GroupImg}
                        alt="delete"
                      />
                    </div>
                  </div>
                  <Modal
                    show={showDeletePlaylistModel}
                    onHide={() => setShowPlaylistModel(false)}
                  >
                    <div className="modelMain">
                      <Modal.Body className="modelBody ">
                        <div className="d-flex">
                          <div className="row">
                            <div className="col d-flex ">
                              <h3 className="deletePlaylistTitle">
                                Are you sure you want to delete this video?
                              </h3>
                            </div>
                            <div className="col modelFooter mt-4">
                              <button
                                style={{ width: "160px" }}
                                className="mainButton modelBtn"
                                variant="secondary"
                                onClick={handleDeleteAttribute}
                              >
                                Yes, remove it
                              </button>
                              <button
                                className="mainButton modelBtn"
                                style={{
                                  backgroundColor: "#AA23AD",
                                  color: "#F2F4F6",
                                  width: "160px",
                                }}
                                variant="primary"
                                onClick={() => setShowPlaylistModel(false)}
                              >
                                No, i just clicked it
                              </button>
                            </div>
                          </div>
                          <img width={124} src={pigImg} alt="" />
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer></Modal.Footer> */}
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="playlistTable container">
                {getAttributesById.loading === false ? (
                  <div className="row tableMain">
                    <ul style={{ listStyleType: "none" }}>
                      {/* <TableLiElement white={true} /> */}
                      <TableLiElement />
                      {/* <TableLiElement white={true} /> */}
                    </ul>
                  </div>
                ) : null}
              </div>
              <Link to="/home/activity/add-attribute">
                <button className="addPlaylistBtn primaryBtn">
                  Add Atribute
                </button>
              </Link>
            </div>
          </div>
          <div
            style={{ width: "40%" }}
            className="container mx-auto modelFooter"
          >
            <button
              className="mainButton modelBtn"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="mainButton modelBtn"
              variant="primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddActivity;
