import React from 'react'
import { TextField, styled, Grid, Typography } from '@mui/material';
import styles from "./banner.module.css";
import uploads from "../../../Assets/images/uploads.png";
import { useState, useRef, } from 'react';
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5"
import { Link } from "react-router-dom";
import { newbanner } from '../../../services/banner';

const Labels = styled(Typography)`
color: #878787;
font-size: 12px;
margin: 5px 0px
`

const AddBanner = () => {

    const navigate = useNavigate();

    const initialValues = { bannerName: "", validFrom: "", validTill: "", description: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [image, setImage] = useState("")
    const [formErrors, setFormErrors] = useState({});

    const inputRef = useRef(null);

    const handleImageClick = () => {
        inputRef.current.click();
    }

    const handleImageChange = (e) => {
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        console.log(e.dataTransfer.files[0])
        setImage(e.dataTransfer.files[0]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log("form data", formValues);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        const formdata = new FormData()
        formdata.append("image", image);
        formdata.append("name", formValues.bannerName);
        formdata.append("validFrom", formValues.validFrom);
        formdata.append("validTill", formValues.validTill);
        formdata.append("description", formValues.description);

        if (formValues.bannerName && formValues.validFrom && formValues.validTill && formValues.description) {
            
            let { data: res } = await newbanner(formdata);
            console.log(res.success);
            alert("banner added")
            console.log("data added to form ");
            setTimeout(() => {
                navigate("/banner");
            }, 1500);
        }
    }

    const validate = (values) => {
        const errors = {};

        if (!values.bannerName) {
            errors.bannerName = "Required";
        }
        if (!values.validFrom) {
            errors.validFrom = "Required";
        }
        if (!values.validTill) {
            errors.validTill = "Required";
        }
        if (!values.description) {
            errors.description = "Required";
        }
        return errors;
    };
    // IoCaretBackCircleOutline

    return (

        <div className={styles.container}>

            <div>
                <Link to="/banner">
                    <IoArrowBackCircle className={styles.backbutton} />
                </Link>

                <p className={styles.headertext}>Add Banner</p>
            </div>
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        name="bannerName"
                        value={formValues.bannerName}
                        onChange={handleChange}
                        className={styles.inputs} />
                    <small className="text-red">{formErrors.bannerName}</small>

                    <Labels>Valid From</Labels>
                    <input
                        type="date"
                        label="Valid From"
                        name="validFrom"
                        value={formValues.validFrom}
                        onChange={handleChange}
                        className={styles.addDate}></input>
                    <small className="text-red">{formErrors.validFrom}</small>

                    <Labels>Valid Till</Labels>
                    <input
                        type="date"
                        label="Valid Till"
                        name="validTill"
                        value={formValues.validTill}
                        onChange={handleChange}
                        className={styles.addDate}></input>
                    <small className="text-red">{formErrors.validTill}</small>
                </Grid>
                <Grid item xs={6}>
                    <div onClick={handleImageClick} onDragOver={handleDragOver} onDrop={handleDrop}>
                        <div >
                            {image ?
                                (<img src={URL.createObjectURL(image)} className={styles.addUpload} />)
                                : (< img src={uploads} className={styles.addUpload} />)}
                        </div>
                        <input
                            type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }}></input>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        name="description"
                        onChange={handleChange}
                        multiline
                        value={formValues.description}
                        rows={7}
                        defaultValue=""
                        className={styles.addDescription}
                    />
                    <small className="text-red">{formErrors.description}</small>
                </Grid>
                <Grid item xs={12}>
                    <button className={styles.button} onClick={handleSubmit}>
                        Save Details
                    </button>
                </Grid>
            </Grid>

        </div>

    )
}

export default AddBanner


