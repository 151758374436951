import API from '../axios/api'
import axios from 'axios'

const BASE_LINK = API.BASE_LINK

export const getAllCategories = async () => {
  return await axios.get(BASE_LINK + '/category/')
}
export const getSingleCategory = async id => {
  return await axios.get(BASE_LINK + `/category/getById/${id}`)
}

export const addNewCategory = async formData => {
  return await axios.post(BASE_LINK + '/category', formData)
}
export const updateSingleCategory = async (id, formData) => {
  return await axios.patch(BASE_LINK + `/category/updateById/${id}`, formData)
}

export const deleteSingleCategory = async id => {
  return await axios.delete(BASE_LINK + `/category/deleteById/${id}`)
}
