import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { getParentDetails } from "../../../services/User";
import Swal from "sweetalert2";
import moment from "moment";

const DetailOfUser = () => {
  let { id } = useParams();
  const [userDetail, setUserDetail] = useState("");

  const getParentDetailsFunc = async (id) => {
    try {
      let { data: res } = await getParentDetails(id);
      console.log(res.data);
      if (res.success == true) {
        setUserDetail(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message, "this is errore");
      }
    }
  };

  useEffect(() => {
    getParentDetailsFunc(id);
  }, []);

  console.log(userDetail, "userDetail");

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          style={{ paddingLeft: "4em", paddingTop: "4em" }}
          className="d-flex flex-wrap"
        >
          <div className="col-12 user-all-details">
            <p>
              Parent Name: {userDetail.firstName} {userDetail.lastName}{" "}
            </p>
            <p>Parent Phone: {userDetail.phone}</p>
            <p>Parent Email: {userDetail.email}</p>
            <p>Referral Code: {userDetail.referralCode}</p>
            <p>Kid Count: {userDetail.kidCount}</p>
            <p>Parent Wallet Balance: {userDetail.balanace}</p>
            <p>
              Wallet Update Date:{" "}
              {moment(userDetail.walletUpdateDate).format("ll")}{" "}
            </p>
            <p>Family Balance: {userDetail.totalFamilyAmount}</p>
            <p>Subscription Used: {userDetail.subscriptionName}</p>
            <p>Coupon Used: {userDetail.couponName}</p>
            <p>
              Parent Created Date: {moment(userDetail.createdAt).format("ll")}
            </p>
            <p>Updated Date: {moment(userDetail.updatedAt).format("ll")}</p>

            <div className="myaccount-table table-responsive text-center">
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Amount</th>
                    <th>Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {userDetail?.AllKid?.map((childData, index) => {
                    // console.log(el?.kidData?.dob, 'el?.kidData?.dob')
                    let kidYear = childData?.userId?.dob?.split("/")[1];

                    // console.log(kidYear, 'kid year')
                    var now = new Date();
                    let currentYear = now.getFullYear();
                    var kidAge = currentYear - kidYear;
                    return (
                      <tr>
                        <td>
                          {childData.userId.firstName}{" "}
                          <strong>({childData.userId.referralCode})</strong>
                        </td>
                        <td>{kidAge ? kidAge : '0'} Yrs</td>
                        <td>{childData.amount}</td>
                        <td>
                          {moment(childData.userId.createdAt).format("ll")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailOfUser;
