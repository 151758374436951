// getParents
import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/users`;

export const getRegisteredParents = async () => {
  return await axios.get(`${BASE_LINK}/getParents`);
};

export const getParentDetails = async (id) => {
  console.log("id", id);
  return await axios.get(`${BASE_LINK}/getParentDetails/${id}`);
};

export const getKidDataByParentId = async (id) => {
  return await axios.get(`${BASE_LINK}/getUserDataById/${id}`);
};

export const deleteParentById = async (id) => {
  return await axios.delete(`${BASE_LINK}/deleteUser/${id}`);
};

export const updateUserStatus = async (id, formData) => {
  console.log(id, 'user id', formData)
  return await axios.post(`${BASE_LINK}/updateUserTag/${id}`, formData);
};

export const getallKids = async () => {
  return await axios.get(`${BASE_LINK}/getKids`);
};

export const getRegisteredDevices = async () => {
  return await axios.get(`${BASE_LINK}/getdevicedetails`);
};