import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const OrderModal = ({ orderdet, show, handleShow }) => {
  console.log("item order modal", orderdet);
  const [qtyArr, setQtyArr] = useState(0);

  useEffect(() => {
    let qtrArray = [];
    let proLi = orderdet.products_List;
    proLi?.forEach((element) => {
      qtrArray.push(element.qty);
    });
    setQtyArr(parseInt(qtrArray.reduce((partialSum, a) => partialSum + a, 0)));
  }, []);

  return (
    <>
      <Modal
        show={show}
        orderdet={orderdet}
        onHide={handleShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="order-det-modal"
      >
        <Modal.Body>
          <div id="ContentDiv">
            <div className="single-account-wrapper">
              <h2 className="tabcontent-title">
                Order Id : {orderdet.order_Id}
              </h2>
              <p className="tabcontent-subtitle">
                {" "}
                Was placed on{" "}
                <span>
                  <strong>{moment(orderdet.createdAt).format("llll")}</strong>
                </span>
                {/* and is currently <span>On Cancelled </span> */}
              </p>
              <div className="myaccount-info-wrapper">
                <div className="order_modal">
                  {orderdet?.products_List?.map((prod, index) => {
                    return (
                      <>
                        <div className="order_modal_details flex-column flex-md-row">
                          <div className="product_details-image">
                            <div className="order_modal_details__product-image mx-auto">
                              <img
                                src={prod?.product_id?.imagePath}
                                alt=""
                                width="100"
                                height="100"
                              />
                            </div>
                          </div>
                          <div className="product_details-content">
                            <h4 className="order_modal_details__product-name">
                              {prod?.product_id?.product_name}
                            </h4>
                            <p className="order_modal_details__product-quantity">
                              Qty: {qtyArr}
                            </p>
                            <p className="order_modal_details__product-quantity">
                              Amount:  ₹ {prod.product_Amount}/-
                            </p>
                            {/* <p className="order_modal_details__product-price">
                          ₹ {orderdet.orderAmount}
                          </p> */}
                            {/* {if(prod?.variant?.color){
                          return (
                            <p className="order_modal_details__product-price">
                          {prod?.variant?.color}
                          </p>
                          )
                          } else if(prod?.variant?.quantity){
                          return (
                            <p className="order_modal_details__product-price">
                          {prod?.variant?.quantity}
                          </p>
                          )
                          }
                          } */}
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="order_modal_total">
                    <div className="order_modal_total__subtotal">
                      <p>Phone No.</p>
                      <p className="order_modal_total__subtotal-price">
                        {orderdet?.shipping_Address?.phone}
                      </p>
                    </div>
                    <div className="order_modal_total__subtotal">
                      <p>User Name</p>
                      <p className="order_modal_total__subtotal-price">
                        {orderdet?.userId?.firstName}&nbsp;
                        {orderdet?.userId?.lastName} 
                      </p>
                    </div>
                    <div className="order_modal_total__subtotal">
                      <p>Address</p>
                      <p className="order_modal_total__subtotal-price order-det-address">
                        {orderdet?.shipping_Address?.address}, &nbsp;
                        {orderdet?.shipping_Address?.locality}, &nbsp;
                        {orderdet?.shipping_Address?.city}, &nbsp;
                        {orderdet?.shipping_Address?.state}
                      </p>
                    </div>

                    <div className="order_modal_total__subtotal">
                      <p>Pin Code</p>
                      <p className="order_modal_total__subtotal-price">
                        {orderdet?.shipping_Address?.pinCode}
                      </p>
                    </div>

                    <div className="order_modal_total__subtotal payvalue">
                      <p className="total">Total Amount</p>
                      <p className="order_modal_total__subtotal-price total">
                      ₹ {orderdet.orderAmount}/-
                      </p>
                    </div>
                    <div className="order_modal_total__subtotal payvalue">
                      <p className="total">Status</p>
                      <p className="order_modal_total__subtotal-price total order-det-status">
                        {orderdet?.order_Status === 'cancelledBySeller' ? 'Cancelled By Seller' : orderdet?.order_Status === 'orderPlaced' ? 'Order Placed' : orderdet?.order_Status === 'confirmed' ? 'Confirmed' : orderdet?.order_Status === 'readyToShip' ? 'Ready To Ship' : orderdet?.order_Status === 'shipped' ? 'Shipped' : orderdet?.order_Status === 'outForDelivery' ? 'Out For Delivery' : orderdet?.order_Status === 'delivered' ? 'Delivered' : orderdet?.order_Status === 'return' ? 'Return' : orderdet?.order_Status === 'returned' ? 'Returned' : 'Pending'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderModal;
