import React, { useEffect, useState } from 'react'
import NavbarComponent from '../../../../Component/Navbar'
import SidenavTabs from '../../../../Component/SidenavTabs'
import uploadIcon from '../../../../Assets/images/uploadicon.png'
import editImg from '../../../../Assets/images/edit.png'
import EllipseImg from '../../../../Assets/images/Ellipse.png'
import GroupImg from '../../../../Assets/images/Group.png'
import pigImg from '../../../../Assets/images/mascot.png'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router'
import {
  getSingleActivity,
  updateSingleActivity
} from '../../../../services/activity'
import { getSingleCategory } from '../../../../services/category'
import {
  deleteSingleAttribute,
  getAttributesByActivityId
} from '../../../../services/attributes'
import Swal from 'sweetalert2'
import {
  getSinglePlaylist,
  updateSinglePlaylist
} from '../../../../services/playlist'
import {
  deleteSingleVideo,
  getAllVideos,
  getSingleVideo
} from '../../../../services/videos'

function UpdatePlaylist() {
  const [fromCount, setFromCount] = useState('')
  const [toCount, setToCount] = useState('')
  const [showDeletePlaylistModel, setShowDeletePlaylistModel] = useState(false)
  const [showPlaylistModel, setShowPlaylistModel] = useState(false)
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [fileLink, setFileLink] = useState('')
  const [file, setFile] = useState('')
  const [activityObj, setActivityObj] = useState({})
  const [attributesArr, setAttributesArr] = useState([])
  const [categoryObj, setCategoryObj] = useState({})
  //formdata
  const [selectedAttribute, setSelectedAttribute] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  let navigate = useNavigate()
  let id = useParams()

  const handleClose = () => {
    setFile('')
    navigate('/activities')
  }
  const handleNavigation = () => {
    navigate('/home')
  }
  const handleSubmit = e => {
    e.preventDefault()
    // dispatch(updateActivityByid(actName, itemId, selectedFile, fromCount, toCount))
    navigate('/home/activity')
  }

  const handleOnint = () => {
    getPlaylistDataById()

    // getAttributeDataByActivityId();
  }

  useEffect(() => {
    handleOnint()
  }, [])

  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage('')
    }
  }, [fromCount, toCount])

  const getPlaylistDataById = async () => {
    try {
      let { data: res } = await getSinglePlaylist(id.id)
      if (res.success == true) {
        console.log(res.data)
        setActivityObj(res?.data)
        setName(res.data.name)
        setFromCount(res.data.ageGroupObj.startAge)
        setToCount(res.data.ageGroupObj.endAge)
        getVideoDataByPlaylistId()
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message)
      } else {
        Swal.fire(error?.message)
      }
    }
  }
  const getVideoDataByPlaylistIdss = async id => {
    try {
      let { data: res } = await getAttributesByActivityId(id)
      if (res.success == true) {
        setCategoryObj(res?.data)
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message)
      } else {
        Swal.fire(error?.message)
      }
    }
  }
  const getVideoDataByPlaylistId = async () => {
    try {
      let { data: res } = await getAllVideos(id.id)
      if (res.success == true) {
        console.log(res.data)
        setAttributesArr(res?.data)
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message)
      } else {
        Swal.fire(error?.message)
      }
    }
  }

  const handleActivityEdit = async () => {
    try {
      let obj = {
        startAge: fromCount,
        endAge: toCount
      }

      let formData = new FormData()
      formData.append("name", name)
      formData.append("ageGroupObj", JSON.stringify(obj))
      formData.append("file", file)


      const { data: res } = await updateSinglePlaylist(id.id, formData)
      if (res.success) {
        console.log(res.data)
        Swal.fire(res.message)
        handleOnint()
        clearstates()
        navigate(-1)
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message)
      } else {
        Swal.fire(error?.message)
      }
    }
  }

  const handleAttributeDelete = async () => {
    try {
      const { data: res } = await deleteSingleVideo(selectedAttribute?._id)
      if (res.success) {
        console.log(res.data)
        Swal.fire(res.message)
        handleOnint()
        setShowDeletePlaylistModel(false)
        clearstates()
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message)
      } else {
        Swal.fire(error?.message)
      }
    }
  }

  const handleModalOpen = () => {
    if (selectedAttribute._id) {
      setShowDeletePlaylistModel(true)
    } else {
      Swal.fire('please select an attribute to proceed further')
    }
  }
  const handleEditRedirect = () => {
    if (selectedAttribute._id) {
      navigate(`/video/update/${selectedAttribute._id}`)
    } else {
      Swal.fire('please select an attribute to proceed further')
    }
  }
  const handleAddAttributeRedirect = () => {
    navigate(`/home/activity/add-attribute/${id.id}`)
  }

  const clearstates = () => {
    setName('')
    setFromCount(0)
    setFileLink('')
    setToCount(0)
  }

  const handleFromCount = e => {
    if (fromCount >= toCount) {
      setErrorMessage('cannot be greaterthanTo')
    } else {
      setFromCount(prevState => parseInt(prevState) + 1)
    }
  }
  const handleToCount = e => {
    if (toCount <= fromCount) {
      setErrorMessage('cannot be lessthanFrom')
    } else {
      setToCount(prevState => parseInt(prevState) - 1)
    }
  }

  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage('cannot be lessthan0')
    } else {
      setFromCount(prevState => parseInt(prevState) - 1)
    }
  }

  const TableLiElement = props => {
    return (
      <table id='resultTable'>
        <thead>
          <tr id='first' className='table-title'>
            <th>Video Name</th>
            <th>Video URL</th>
            <th>Age Group</th>
            {/* <th>Coins Req.</th> */}
          </tr>
        </thead>
        <tbody>
          {attributesArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className='d-flex'>
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                      console.log('selected: ', el)
                      setSelectedAttribute(el)
                    }}
                    type='radio'
                    checked={selectedAttribute._id == el._id}
                  />
                  <p className='tableInputTxt' type='text' name='column_1'>
                    {el?.videoHeading}
                  </p>
                </div>
              </td>
              <td>
                <div className='d-flex'>
                  <p className='tableInputTxt' type='text' name='column_1'>
                    {el?.videoUrl}
                  </p>
                </div>
              </td>
              <td>
                <div className='d-flex'>
                  <p className='tableInputTxt' type='text' name='column_1'>
                    {el?.ageGroupObj.startAge} - {el?.ageGroupObj.endAge}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <div>
      <div className='home-outer'>
        <div className='innerPageContent'>
          <div className='modelMain'>
            <div className='container'>
              <div className=' m-2 row flex-row'>
                <div className='catName d-flex flex-row w-75 m-0'>
                  <h2 className='my-auto p-2 primaryText'>
                    {categoryObj?.name}
                  </h2>
                </div>
                <div className='catName d-flex flex-row w-75 m-0'>
                  <label className='my-auto p-2'>Playlist Name: </label>

                  <input
                    className='catInput w-50'
                    placeholder='Type playlist Name Here'
                    value={name}
                    onChange={e => setName(e.target.value)}
                  ></input>
                </div>

                <div className='row container-fluid'>
                  <div className='col-4 uploadImg'>
                    <label className='p-2'>Edit Playlist Image:</label>
                    <div style={{ marginLeft: 0 }} className='upload-outer p-1'>
                      <div
                        style={{ position: 'relative', marginLeft: '2em' }}
                        className=''
                      ></div>
                    </div>
                    <div style={{ marginLeft: 0 }} className='upload-outer p-1'>
                      <input
                        className='catInput inputFile'
                        type='file'
                        onChange={e => setFile(e.target.files[0])}
                      ></input>
                      <p>{file && file.name}</p>
                      {/* <img
                        style={{
                          position: 'absolute',
                          left: '46%',
                          top: '40%'
                        }}
                        src={uploadIcon}
                        alt='upload'
                      /> */}
                    </div>
                  </div>
                  <div className='col '>
                    <div className='ageGroup p-2'>
                      <label>Age Group: </label>
                    </div>
                    <div className='ageGroup flex-column '>
                      <div className='ageGroup-inner'>
                        <p>From</p>
                        <div className='agegroupBtn-outer'>
                          <button
                            className='agegroupBtn'
                            onClick={handleFromCount}
                          >
                            +
                          </button>
                          <input
                            className='ageCountInput'
                            value={fromCount}
                            type='number'
                            maxLength={10}
                            onChange={e => setFromCount(e.target.value)}
                          />
                          <button
                            style={{ marginRight: '-2px' }}
                            className='agegroupBtn'
                            onClick={handleNegativeFromCount}
                          >
                            -
                          </button>
                        </div>
                        <p>To</p>
                        <div className='agegroupBtn-outer'>
                          <button
                            className='agegroupBtn'
                            onClick={() =>
                              setToCount(prevState => parseInt(prevState) + 1)
                            }
                          >
                            +
                          </button>
                          <input
                            className='ageCountInput'
                            value={toCount}
                            type='number'
                            maxLength={10}
                            onChange={e => setToCount(e.target.value)}
                          />
                          <button
                            style={{ marginRight: '-2px' }}
                            className='agegroupBtn'
                            onClick={handleToCount}
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginLeft: '2em'
                      }}
                    >
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ width: '100%' }}
            className='container-fluid row mx-auto'
          >
            <div className='col-md px-5'>
              <div className='row'>
                <div className='row container '>
                  <div className='col playlistTitle '>
                    <h3>Videos ({attributesArr.length})</h3>
                  </div>
                  <div className='col-lg-3'>
                    <div className='icon-outer'>
                      {/* <img
                        onClick={() => handleAddAttributeRedirect()}
                        className="tableIcon"
                        src={EllipseImg}
                        alt=""
                      /> */}
                      <img
                        onClick={() => handleEditRedirect()}
                        className='tableIcon'
                        src={editImg}
                        alt=''
                      />
                      <img
                        onClick={() => handleModalOpen()}
                        className='tableIcon'
                        src={GroupImg}
                        alt='delete'
                      />
                    </div>
                  </div>
                  <Modal
                    show={showDeletePlaylistModel}
                    onHide={() => setShowDeletePlaylistModel(false)}
                  >
                    <div className='modelMain'>
                      <Modal.Body className='modelBody '>
                        <div className='d-flex'>
                          <div className='row'>
                            <div className='col d-flex '>
                              <h3 className='deletePlaylistTitle'>
                                Are you sure you want to delete this video?
                              </h3>
                            </div>
                            <div className='col modelFooter mt-4'>
                              <button
                                style={{ width: '160px' }}
                                className='mainButton modelBtn'
                                variant='secondary'
                                onClick={() => handleAttributeDelete()}
                              >
                                Yes, remove it
                              </button>
                              <button
                                className='mainButton modelBtn'
                                style={{
                                  backgroundColor: '#AA23AD',
                                  color: '#F2F4F6',
                                  width: '160px'
                                }}
                                variant='primary'
                                onClick={() =>
                                  setShowDeletePlaylistModel(false)
                                }
                              >
                                No, i just clicked it
                              </button>
                            </div>
                          </div>
                          <img width={124} src={pigImg} alt='' />
                        </div>
                      </Modal.Body>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className='playlistTable'>
                <div className=''>
                  <div className='row tableMain'>
                    <ul style={{ listStyleType: 'none' }}>
                      <TableLiElement />
                    </ul>
                  </div>
                </div>
              </div>
              <Link to={`/playlist/add-video/${id.id}`}>
                <button
                  style={{ marginRight: '3em' }}
                  className='addPlaylistBtn primaryBtn '
                >
                  Add Video
                </button>
              </Link>
            </div>
          </div>
          <div
            style={{ width: '40%' }}
            className='container mx-auto modelFooter'
          >
            <button
              className='mainButton modelBtn'
              variant='secondary'
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className='mainButton modelBtn'
              variant='primary'
              onClick={handleActivityEdit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdatePlaylist
