// createOrder
import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/userWallet`;
const AXIS_BASE_LINK = `${API.BASE_LINK}/axis`;

export const createOrder = async (id) => {
  return await axios.post(BASE_LINK + `/web/CreateTransaction/${id}`);
};

export const createBlessingOrder = async (token, body) => {
  return await axios.post(
    BASE_LINK + `/web/createBlessingTransaction/${token}`,
    body
  );
};

export const getAllWallet = async (id, formData) => {
  return await axios.get(`${BASE_LINK}/All`);
};

export const addBonusAmount = async (data) => {
  return await axios.post(`${BASE_LINK}/addBonusAmount`, data);
};
