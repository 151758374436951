import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../Assets/Styling/adminHome.css";
import Styles from './Activities.module.css';
import uploadIcon from "../../../Assets/images/uploadicon.png";
import Nutrition from "../../../Assets/images/Nutrition.png";
import deleteImg from "../../../Assets/images/delete.png";
import pencil from "../../../Assets/images/pencil.png";
import pigImg from "../../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import { addNewCategory, deleteSingleCategory, getAllCategories, updateSingleCategory } from "../../../services/category";
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2'

export default function Activities() {
    const [categoryArr, setCategoryArr] = useState([]);
    const navigate = useNavigate()
    const getCategories = async () => {
        try {
            const { data: res } = await getAllCategories();
            if (res.success) {
                console.log(res.data)
                setCategoryArr(res.data)
                // Swal.fire("hii")
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message)
            }
            else {
                Swal.fire(error?.message)
            }
        }
    }

    const handleOnint = () => {
        getCategories()
    }
    useEffect(() => {
        handleOnint()
    }, [])




    const handleRedirect = (item)=> {
        navigate(`/home/activity/${item?._id}`)
    } 



    return (
        <div className="container-fluid">
            <div className="row">
                <div style={{paddingLeft: '4em', paddingTop: '4em'}}  className="d-flex flex-wrap">


                    {
                        categoryArr?.map((el, i) => {
                            return (

                                <>
                                    <div key={i} className="actItem" onClick={()=> handleRedirect(el)}>
                                        <div className="menu"></div>
                                        {/* //add no image condition  */}
                                        {el.thumbnailImage == "" ? (
                                            <img width={111} height={132} className="actItem" src={Nutrition} alt="catItem" />
                                        ) : (
                                            <img width={111} height={132} className="actitemImg" src={el.thumbnailImage} alt="catItem" />
                                        )}

                                        <p className="catitemName">{el.name}</p>
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </div>
    )
}
