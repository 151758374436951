import React from 'react'
import { TextField, styled,  Typography, MenuItem } from '@mui/material';
import styles from "./stickers.module.css";
import uploads from "../../../Assets/images/uploads.png";
import { useState, useRef, } from 'react';
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5"
import { Link } from "react-router-dom";
import { newsticker } from '../../../services/sticker';

const Labels = styled(Typography)`
color: #878787;
font-size: 12px;
margin: 5px 0px;
`
const Field = styled(TextField)`
margin-top:20px;`


const AddSticker = () => {

    const navigate = useNavigate();

    const initialValues = { name: "", type: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [image, setImage] = useState("")
    const [formErrors, setFormErrors] = useState({});

    const inputRef = useRef(null);

    const handleImageClick = () => {
        inputRef.current.click();
    }

    const handleImageChange = (e) => {
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        console.log(e.dataTransfer.files[0])
        setImage(e.dataTransfer.files[0]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log("form data", formValues);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        const formdata = new FormData()
        formdata.append("image", image);
        formdata.append("name", formValues.name);
        formdata.append("type", formValues.type);

        if (formValues.name && formValues.type) {

            let { data: res } = await newsticker(formdata);
            console.log(res.success);
            console.log("data added to form ");
            console.log(formValues, image);
            setTimeout(() => {
                navigate("/stickers");
            }, 1500);
        }
    }

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.type) {
            errors.type = "Required";
        }
        return errors;
    };


    return (

        <div className={styles.addcontainer}>
            <div className={styles.box} >

                <Link to="/stickers">
                    <IoArrowBackCircle className={styles.backbutton} />
                </Link>

                <p className={styles.headertext}>Add Sticker</p>

                <div onClick={handleImageClick} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <div >
                        {image ?
                            (<img src={URL.createObjectURL(image)} className={styles.addUpload} />)
                            : (< img src={uploads} className={styles.addUpload} />)}
                    </div>
                    <input
                        type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }}></input>
                </div>
                <Field
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    className={styles.inputs} />
                <small className="text-red">{formErrors.name}</small>

                <Field
                    id="outlined-select"
                    select
                    label="Type"
                    variant="outlined"
                    name="type"
                    value={formValues.type}
                    onChange={handleChange}
                    className={styles.inputs} >
                    <MenuItem value="Kids">Kids</MenuItem>
                    <MenuItem value="Parent" >Parent</MenuItem>
                    <MenuItem value="Blessings" >Blessings</MenuItem>
                </Field>
                <small className="text-red">{formErrors.type}</small>


                <div>
                <button className={styles.button} onClick={handleSubmit}>
                    Save Details
                </button>

                </div>

            </div>
        </div>

    )
}

export default AddSticker


