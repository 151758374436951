import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import styles from "./ecommerce.module.css";
import {
  getallproduct,
  productStatus,
  productDelete,
  updateProduct,
} from "../../../services/Ecommerce/Product";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/mascot.png";
import Swal from "sweetalert2";
import {
  allcategories,
  allsubcategoriesByCategoties,
} from "../../../services/Ecommerce/category";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import Select from "react-select";
// import Editproduct from "./Editproduct";

const Productlist = () => {
  const navigate = useNavigate();
  const [productlist, setproductlist] = useState([]);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    getproduct();
  }, [checked, showAddModal]);

  const getproduct = async () => {
    let { data: res } = await getallproduct();
    setproductlist(res.data);
  };

  // console.log("productslist", productlist);

  const handleChangeSwitch = async (id, length) => {
    console.log("id", id);
    console.log("length", length);
    if (length > 0) {
      let { data: res } = await productStatus(id);
      console.log("checked value", res.data);
      setchecked(!checked);
    } else {
      alert("Please First Add Variants");
    }
  };

  const delModelHandle = async (id) => {
    console.log("Delete", id);
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const deleteParent = async (id) => {
    try {
      let { status } = await productDelete(id);
      if (status === 200) {
        console.log("deleted Category Done", id);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const handleEdit = (item) => {
    setShowAddModal(true);
    // <Editproduct
    //   showAddModal={showAddModal}
    //   setShowAddModal={setShowAddModal}
    // />;
  };

  const sendEditProd = (row) => {
    navigate(`/ecommerce/product/${row._id}`, { state: row });
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>Product List</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Link to="/ecommerce/product/addproduct">
              <button className="button2">Add Product</button>
            </Link>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Product Name</TableCell>
                <TableCell align="center">Product Img</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Total Stocks</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productlist?.map((row, index) => (
                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.product_name}</TableCell>
                  <TableCell align="center">
                    <img
                      src={row.imagePath}
                      alt={row.product_name}
                      height="40"
                      width="50"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row.category?.map((item, index) => {
                      return <span>{item.categoryname}, </span>;
                    })}
                  </TableCell>
                  <TableCell align="center">{row.variants?.length}</TableCell>
                  <TableCell align="center">
                    {row.default_Variant
                      ? row.default_Variant?.selling_price
                      : "Add Variants"}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={row.status}
                      onChange={() =>
                        handleChangeSwitch(row._id, row.variants?.length)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Link to={`/ecommerce/product/${row?._id}/variants`}>
                        <FaPlus className="product-update product-add" />
                      </Link>
                      <MdDelete
                        onClick={() => delModelHandle(row._id)}
                        className="product-delete"
                      />
                      {/* <Link to={`/ecommerce/product/${row._id}`}>
                        <BiEdit className="product-update" />
                      </Link> */}
                      {/* <BiEdit
                        onClick={() => {
                          handleEdit(row);
                          setS
                        className="product-update"
                      /> */}
                      <BiEdit
                        // onClick={() => {
                        //   handleEdit(row);
                        //   setSelectedProduct(row);
                        // }}
                        onClick={() => {
                          sendEditProd(row);
                        }}
                        className="product-update"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deleteParent(x)}
      />

      {showAddModal && (
        <EditModel
          show={showAddModal}
          prodData={selectedProduct}
          setShowAddModal={setShowAddModal}
          setShow={() => {
            setSelectedProduct("");
            setShowAddModal(false);
          }}
        />
      )}
    </>
  );
};

export default Productlist;

const EditModel = ({ show, prodData, setShow, setShowAddModal }) => {
  console.log(prodData, "prod da");
  const [allcategory, setAllcategory] = useState([]);

  const initialValues = {
    product_name: prodData.product_name,
    category: prodData?.category?.map((x) => {
      return {
        label: x.categoryname,
        value: x._id,
      };
    }),
    subCategories: prodData?.subCategories?.map((x) => {
      return {
        label: x.categoryname,
        value: x._id,
      };
    }),
    description: prodData.description,
    return_policy: prodData.return_policy,
    madein: "",
    return: "",
    agefrom: "",
    ageto: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [fetchCatId, setFetchCatId] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const [files, setFiles] = useState({});
  const [src, setSrc] = useState([]);

  useEffect(() => {
    getallcategories();
  }, []);

  const getallcategories = async () => {
    let { data: res } = await allcategories();
    res?.data?.forEach((element) => {
      element.label = element.categoryname;
      element.value = element._id;
    });
    setAllcategory(res.data);
  };

  const getSubCategory = async (name, data) => {
    // console.log(data, "data fromk cat");
    let fetchCatId = "";
    if (name === "category") {
      setSubCategory([]);
      setFormValues({ ...formValues, [name]: data });
      console.log("cat data", data);
      fetchCatId = data.map((x) => x._id);
      if (fetchCatId) {
        // console.log(fetchCatId, "fetch id");

        let { data: res } = await allsubcategoriesByCategoties(fetchCatId);
        // console.log(res.data, "sub cat fetched");
        res?.data?.forEach((element) => {
          element.label = element.categoryname;
          element.value = element._id;
          // console.log(element);
        });
        setSubCategory(res.data);
      }
    } else {
      console.log("running me", data);
      setSelectedSubCat(data);
    }
  };

  const handleMultiple = (name, data) => {
    setFormValues({ ...formValues, [name]: data });
    console.log("cat", data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const editSubmitHandler = async () => {
    console.log("id");
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("product_name", formValues.product_name);
    formdata.append("description", formValues.description);
    formdata.append("return_policy", formValues.return_policy);
    formdata.append(
      "category",
      formValues.category.map((x) => x.value)
    );
    formdata.append(
      "subCategories",
      formValues.subCategories.map((x) => x.value)
    );

    if (
      formValues.product_name &&
      formValues.description &&
      formValues.return_policy &&
      formValues.category
    ) {
      let { data: res } = await updateProduct(prodData?._id, formdata);
      console.log("res", res);
      if (res.status) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.product_name) ||
      /^[ ]+$/.test(values.product_name)
    ) {
      errors.product_name = "Product is not valid";
    }

    if (!values.product_name) {
      errors.product_name = "Product is Required";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }

    if (!values.return_policy) {
      errors.return_policy = "Policy is Required";
    }
    return errors;
  };

  return (
    <>
      <Modal show={show} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Product Name:</label>
                <input
                  className="catInput"
                  placeholder="Product Name*"
                  required
                  value={formValues.product_name}
                  name="product_name"
                  onChange={handleChange}
                ></input>
                <small className="text-red">{formErrors.product_name}</small>
              </div>

              <div className="catName m-0 category-flex">
                <label>Category Selection :</label>
                <Select
                  isMulti
                  onChange={(data) => {
                    getSubCategory("category", data);
                  }}
                  options={allcategory}
                  value={formValues.category}
                />
                <br />
                <label>SubCategory Selection :</label>
                <Select
                  isMulti
                  onChange={(data) => {
                    handleMultiple("subCategories", data);
                  }}
                  options={subCategory}
                  value={formValues.subCategories}
                />
                <br />
              </div>
              <div className="catName m-0">
                <label>Description:</label>
                <textarea
                  rows="5"
                  className="catInput"
                  placeholder="Description*"
                  required
                  value={formValues.description}
                  onChange={handleChange}
                  name="description"
                ></textarea>
                <small className="text-red">{formErrors.description}</small>
              </div>
              <div className="catName m-0">
                <label>Return Policy</label>
                <input
                  className="catInput"
                  placeholder="Return Policy*"
                  required
                  value={formValues.return_policy}
                  onChange={handleChange}
                  name="return_policy"
                ></input>
                <small className="text-red">{formErrors.return_policy}</small>
              </div>
              {/* <Grid item xs={12}>
                <label>Age Group: </label>
                <div className="age-flex">
                  <div>
                    <p>From</p>
                    <div className={styles.age}>
                      <button
                        className={styles.agebutton}
                        onClick={() =>
                          setFromCount((prevState) => parseInt(prevState) - 1)
                        }
                      >
                        -
                      </button>
                      <p className={styles.agep}>{fromCount}</p>
                      <button
                        className={styles.agebutton}
                        onClick={() =>
                          setFromCount((prevState) => parseInt(prevState) + 1)
                        }
                      >
                        +
                      </button>
                      <small
                        className="text-red"
                        style={{ paddingLeft: "10px" }}
                      >
                        {formErrors.fromCount}
                      </small>
                    </div>
                  </div>

                  <div>
                    <p>To</p>
                    <div className={styles.age}>
                      <button
                        className={styles.agebutton}
                        onClick={() =>
                          setToCount((prevState) => parseInt(prevState) - 1)
                        }
                      >
                        -
                      </button>
                      <p className={styles.agep}>{toCount}</p>
                      <button
                        className={styles.agebutton}
                        onClick={() =>
                          setToCount((prevState) => parseInt(prevState) + 1)
                        }
                      >
                        +
                      </button>
                      <small
                        className="text-red"
                        style={{ paddingLeft: "10px" }}
                      >
                        {formErrors.toCount}
                      </small>
                    </div>
                  </div>
                </div>
              </Grid> */}
              <div className="uploadImg">
                <label>Upload Image</label>

                <Grid item xs={6}>
                  {!files?.id && (
                    <div className={styles.inputs}>
                      <Files
                        className="files-dropzone"
                        onChange={onFilesChange}
                        onError={onFilesError}
                        accepts={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/webp",
                          "audio/*",
                        ]}
                        multiple
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <img src={uploads} height="100" width={"40%"} />
                      </Files>
                    </div>
                  )}
                  {files?.id && (
                    <div className={styles.inputs}>
                      <Files
                        className="files-dropzone"
                        onChange={onFilesChange}
                        onError={onFilesError}
                        accepts={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/webp",
                          "audio/*",
                        ]}
                        multiple
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <div style={{ background: "white", padding: 20 }}>
                          {src[0] &&
                            src.map((item) => {
                              return (
                                <img
                                  src={item}
                                  height="110"
                                  width={"100"}
                                  style={{
                                    marginRight: 20,
                                    marginBottom: 20,
                                  }}
                                />
                              );
                            })}
                        </div>
                      </Files>
                    </div>
                  )}
                </Grid>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Product ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
