import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/notification`;

export const getAllNotifications = async () => {
  return await axios.get(BASE_LINK);
};

export const addNotification = async (data) => {
  return await axios.post(BASE_LINK, data);
};
export const deleteNotification = async (id) => {
  return await axios.delete(BASE_LINK + `/deleteById/${id}`);
};
